import React, { useEffect, useState, useContext } from 'react';
//Material ui.
import {
    makeStyles, Box
} from '@material-ui/core';
//componente
import TopNav from '../common/TopNav';
//Componentes
import Container from '../Elements/Container';
import AlertComponent from '../Elements/AlertComponent';
import LoadingComponentBlur from '../common/LoadingComponentBlur';
import UserContext from '../../context/UserContext';
import CardComponent from './CardComponent';
//Servicios
import { getpaymentInfo } from '../../services/payments';

const useStyles = makeStyles(theme => ({
    containerPlans: {
        display: 'flex',
        justifyContent: 'flex-start',
        "@media (max-width: 1300px)": {
            flexWrap: 'wrap',
            marginLeft: theme.spacing(1),
            overflow: "hidden", // Oculta cualquier contenido que sobresalga
        },
    }
}))

export default function Payments() {
    const classes = useStyles()
    const { plan } = useContext(UserContext);
    //Estado de la alerta dinamica
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [loadingBlurState, setLoadingBlurState] = useState(false)
    const [plans, setPlans] = useState([])
    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        const getpaymentInfoAux = async () => {
            setLoadingBlurState(true)
            try {
                const response = await getpaymentInfo()
                setUserInfo(response?.data)
                setPlans(response?.data?.plans)
                setLoadingBlurState(false)
            }
            catch (error) {
                setLoadingBlurState(false)
                setMsg({
                    msg: "Error al cargar la información de los planes",
                    type: "error"
                })
                setClose(true)
            }
        }
        getpaymentInfoAux()
    }, [])


    return (
        <>
            <TopNav titulo="Pagos" subtitulo='' />

            {/* Container loading */}
            <LoadingComponentBlur
                loadingState={loadingBlurState}
            />

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />
            <Container >
                <Box width={'100%'} display='flex' justifyContent='center' >
                    <Box className={classes.containerPlans}  >
                        {plans.map((plan) => {
                            return (
                                <CardComponent userInfo={userInfo} key={plan?.id} plan={plan} />
                            )
                        })}
                    </Box>
                </Box>

            </Container >
        </>
    )
}

