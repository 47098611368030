import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale, CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import es from 'date-fns/locale/es'
import Button from '@material-ui/core/Button';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { IconButton, Box, Dialog } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
const useStyles = makeStyles(theme => ({
    dateContainer: {
        margin: "0 2px 0 2px",
        borderStyle: "none",
        '& .react-datepicker__input-container': {
            '& input': {
                width: "100%",
                cursor: "pointer",
                margin: "auto",
                height: "35px",
                fontSize: "15px",
                font: "inherit",
                textAlign: "center",
                color: "#5e5b5b",
                borderColor: "#e2e2e2",
                borderWidth: "1px",
                borderStyle: "none"
            },
        },
        '& .react-datepicker-popper': {
            width: "100%"
        },
        '& .react-datepicker': {
            borderStyle: "none"
        },
        '& .react-datepicker__day-name': {
            color: "#827a7a"
        },
        '& .react-datepicker__day--in-selecting-range': {
            backgroundColor: "#216ba5ad"
        },
        '& .react-datepicker__day': {
            borderRadius: "50% !important",
        },
        '& .react-datepicker__day--today': {
            color: "#88c0ed",
        },
        '& .react-datepicker__header': {
            backgroundColor: "#f4f4f5",
        },
        '& .react-datepicker__month-container': {
            height: "300px",
            padding: "5px",
        },
        '& .react-datepicker__navigation--previous ': {
            top: "10px",
            right: "25px"

        },
        '& .react-datepicker__navigation--next ': {
            top: "10px",
        },

        '& .react-datepicker__day--disabled': {
            color: "#c5c2c2"
        },
    },
    btn: {
        margin: theme.spacing(1, 1),
        fontSize: "13px",
        letterSpacing: "2px",
        minHeight: "37px"
    },
    iconDate: {
        marginLeft: "10px",
    },
    buttonReady: {
        zIndex: "2",
        backgroundColor: "#f4f4f5",
        marginBottom: "8px"
    },
    buttonClose: {
        width: '40px',
    }
}));

const DatePickerCommon = ({
    endDate, startDate,
    displayDisabled, setDate, handleDateChange
}) => {    
    const { isMobile } = useDeviceDetect();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [maxDate] = useState(new Date())
    //Funcion para pasar a español
    registerLocale("es", es)

    // Si el usuario no seleciono segundo dia por default el mismo dia inicial!
    useEffect(() => {
        if (endDate === null) {
            setDate((prevState) => ({
                ...prevState,
                dateFrom: startDate,
                dateTo: startDate
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    //funccion q escucha cambios de fecha.
    const onChanges = (e) => {
        const [start, end] = e;
        if(setDate){
            setDate((prevState) => ({
                ...prevState,
                dateFrom: start,
                dateTo: end
            }))
        }
        handleDateChange(start, end)
    };    

    //Container para poder agregarle elementos al datepicker
    const MyContainer = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                {/* Boton cerrar */}
                <Box
                    style={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>

                    <IconButton
                        className={classes.buttonClose}
                        color='primary'
                        onClick={() => setIsOpen(false)}
                    >
                        <CancelIcon />
                    </IconButton>
                </Box>


                <Box style={{ position: "relative" }}>
                    {children}
                </Box>

                {/* Boton aceptar */}
                <Box
                    style={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: 'center',
                        // backgroundColor: '#f4f4f5',
                    }}>
                    <Button
                        variant='contained'
                        className={classes.buttonReady}
                        size="small"
                        aria-label="upload picture"
                        // component="label"
                        onClick={() => setIsOpen(false)} >
                        Aceptar
                    </Button>
                </Box>
            </CalendarContainer>
        );
    };
    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Dialog
                open={isOpen}
                // onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                disableEnforceFocus
            >
                <Box className={classes.dateContainer}>

                    <DatePicker
                        //formato de fecha
                        dateFormat="dd/MM/yyyy"
                        //Clickeas el input.
                        // onInputClick={() => setIsOpen(true)}
                        //Clickeas afuera.   
                        onClickOutside={() => setIsOpen(false)}
                        open={isOpen}
                        selected={startDate}
                        locale="es"
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onChanges}
                        //transforma el datepicker en dos caras formato libro abierto una por mes.
                        monthsShown={isMobile ? 1 : 2}
                        selectsRange
                        disabled={displayDisabled}
                        maxDate={new Date(maxDate)}
                        // minDate={minDate}
                        // desabilita los dias en formato:
                        // excludeDateIntervals={[{ start: subDays(new Date("12/01/2022"), 0), end: addDays(new Date("01/01/2023"), 0) }]}
                        // excludeDateIntervals={disabledFunction()}
                        //elimina la repeticion de el dia final q seleccionaste en todos los meses, si no usas esta opcion selecciona todos los meses todos los dias iguales al dia end
                        disabledKeyboardNavigation
                        inline
                        //container calendario para poder agregar el boton close
                        calendarContainer={MyContainer}

                    //Agrega un reseteador del datepicker y lo cierra
                    // isClearable={true}

                    //Agrega selector para ir al mes q quieras
                    // showMonthDropdown

                    //deshabilita dias en otra configuracion, dias inBoxidual mente
                    // selectsDisabledDaysInRange
                    />
                </Box>
            </Dialog>

            {/* FECHA NUMERICA */}
            <Box style={{ fontSize: "16px" }} >

                {startDate && `${format(startDate, "dd-MM-yyyy")}`} /  {endDate && format(endDate, "dd-MM-yyyy")}

                {/* {startDate && `${format(startDate, "dd-MM-yyyy")}`}  {endDate && format(startDate, "dd-MM-yyyy") !== format(endDate, "dd-MM-yyyy") && `/ ${format(endDate, "dd-MM-yyyy")}`} */}
            </Box>
            <Box>
                <Button type='submit' disabled={displayDisabled} onClick={handleClick} color='primary' variant='contained' className={classes.btn}>
                    {'Seleccione periodo'}
                    {!isMobile && <InsertInvitationIcon className={classes.iconDate} />}
                </Button>
            </Box>
        </>
    );
}
export default DatePickerCommon