export const DATASET_INITIAL_VALUES = {
    devices: [],
    alias: '',
    variable: '',
    granularity: '',
    timeslots: '',
    color: '',
};

export const FILTER_INITIAL_VALUES = {
    groups: [
        {
            title: 'Grafico Principal',
            disabled: false,
            dataSets: [],
            selectedInitialDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
            selectedEndDate: new Date(),
        },
        {
            title: 'Grafico Secundario',
            disabled: true,
            dataSets: [],
            selectedInitialDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
            selectedEndDate: new Date(),
        },
    ],
};
