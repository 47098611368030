
//icons 
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
export const configBlocks = [
    {
        title: 'Eficiencia Energética',
        items: <ul style={{ listStyleType: 'none' }}>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Monitoreo y vigilancia:</strong> permite acompañar a nuestros clientes en el seguimiento de sus consumos energéticos.</li>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Auditorías energéticas:</strong> permite analizar equipos, instalaciones y flujo energético con el fin de detectar oportunidades de mejora. Contamos con tres niveles de auditorías energéticas dependiendo del tipo de usuario.</li>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Implementación y gestión de proyectos:</strong> Desde Powermeter Consulting podemos asesorar, implementar, gestionar y controlar proyectos eléctricos, de energía renovable, aire comprimido y térmicos.</li>
        </ul>,
        icon: <OfflineBoltIcon style={{ fontSize: '30px' }} />
    },
    {
        title: 'Optimización de contratos',
        items: <ul style={{ listStyleType: 'none' }}>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Contratos Elec. I y II:</strong> revisión de contratos y facturas de energía eléctrica (usuarios +T3). Permite mejorar los costos obteniendo ahorros entre un 5 y 30% sin necesidad de inversión.</li>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Contratos MEM:</strong> propuesta, revisión y gestión de contratos con CAMMESA y entre privados (PPA, Plus, etc). Permite mejorar los costos obteniendo ahorros entre un 10 y 30% sin necesidad de inversión. </li>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Contratos de gas:</strong> propuesta, revisión y gestión de contratos comercializadoras de gas. Permite mejorar los costos obteniendo ahorros entre un 10 y 30% sin necesidad de inversión.</li>
        </ul>,
        icon: <EqualizerIcon style={{ fontSize: '30px' }} />
    },
    {
        title: 'Descarbonización',
        items: <ul style={{ listStyleType: 'none' }}>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Planes de Descarbonización:</strong> El Plan de Descarbonización permite estructurar en una matriz acciones de eficiencia energética, priorizando las mismas en función de su costo-efectividad.</li>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>Huella de Carbono:</strong> Se basa en la medición del impacto ambiental de una organización desde el punto de vista de las emisiones de CO2. Se utilizan estándares y metodologías internacionales como el GHG Protocol y la ISO 14064.</li>
            <li style={{ marginBottom: '7px' }}><strong style={{ color: '#2980b9' }}>ISO 50.001:</strong> Este servicio permite implementar un sistema de gestión de la energía acorde al  estándar ISO. Nuestra metodología de trabajo permite al cliente implementar la norma de una manera ágil y eficiente acompañando al cliente con reuniones virtuales y visitas para planificar, implementar y verificar los requisitos.</li>
        </ul>,
        icon: <DataThresholdingIcon style={{ fontSize: '30px' }} />
    },
    {
        title: 'Proyectos',
        items: <ul style={{ listStyleType: 'none' }}>
            <li style={{ marginBottom: '7px' }}>
                <strong style={{ color: '#2980b9' }}>Implementación de medidas:</strong> A partir del diagnóstico energético se priorizan y se implementan medidas de uso racional y eficiencia energética. Implementamos una amplia gama de proyectos, como por ejemplo:
                <ul>
                    <li style={{ marginBottom: '7px', marginTop: '7px' }}>
                        Energía solar FV; Energía solar térmica;
                    </li>
                    <li style={{ marginBottom: '7px' }}>
                        Banco de capacitores y adecuación de tableros
                    </li>
                    <li style={{ marginBottom: '7px' }}>
                        Recambio de iluminación; Dimensionamiento y provisión de sistemas de aire comprimido; Detección y reparación de fugas de aire comprimido;
                    </li>
                    <li style={{ marginBottom: '7px' }}>
                        Recuperación de calor y mejora de aislaciones
                    </li>
                </ul>
            </li>
        </ul>,
        icon: <NoteAltIcon style={{ fontSize: '30px' }} />
    }
]