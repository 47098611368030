import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function LPF
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={42}
            adderFlag={10}
            showLabelInput={true}
            userConfiguration={true}
        />
    );
}
export default LPF