import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { useContext, useState } from 'react';
import Filter from './Filter';
import TopNav from '../common/TopNav';
import { getHistoryAdvancedV2, getHistoryV2 } from '../../services/meters';
import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import { FILTER_INITIAL_VALUES } from './helpers';
import UserContext from '../../context/UserContext';
import ChartV2Advanced from './ChartV2Advanced';
import { Alert } from '@material-ui/lab';
import Container from '../Elements/Container';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import cloneDeep from 'lodash/cloneDeep';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


const useStyles = makeStyles(theme => ({
  btn: {
    margin: theme.spacing(2, 1),
    '& .MuiButton-label': {
      textTransform: 'none',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  containerChips: {
    marginLeft: '55px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media (max-width: 920px)': {
      flexDirection: 'column',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  btnRefresh: {
    '&.MuiButton-root': {
      height: '30px',

      marginTop: '16px',
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiButton-label': {
      textTransform: 'none',
      paddingLeft: '5px',
      // fontSize: 11,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '@media (max-width: 700px)': {
      marginBottom: '10px',
      marginRight: '10px',
    },
  },
  dateContainer: {
    color: theme.palette.common.white,
    fontSize: 16,
    '& .MuiButtonBase-root': {
      color: theme.palette.common.white,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.common.white,
      width: 125,
      '@media (max-width: 420px)': {
        display: 'none',
      },
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  nextPeriod: {
    '&.Mui-disabled': {
      opacity: '0.5',
    },
  },
}));

export default function HistoricalPro() {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState(() =>
    cloneDeep(FILTER_INITIAL_VALUES)
  );
  const [historicalData, setHistoricalData] = useState([]);
  const [filterPanel, setFilterPanel] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [nameVariableSelected, setNameVariableSelected] = useState("");
  const [devicesNames, setDevicesNames] = useState(null);
  const { plan } = useContext(UserContext);
  const enabledItems = plan?.sections['HISTORICOS'].filter(
    item => item.enabled
  );
  const formatterMonthly = new Intl.DateTimeFormat("es-AR", {
    month: "2-digit",
    year: "numeric",
  });
  const formatterYearly = new Intl.DateTimeFormat("es-AR", {
    year: "numeric",
  });

  const handleSubmit = async filter => {
    if (filter) {
      setErrorMessage('');
      let granularity = filter.granularity;
      let variable = enabledItems?.find(item => item.key === filter.variable);
      let devices = [];
      setNameVariableSelected(variable?.name);
      setFilterOptions({ ...filterOptions, variable: variable?.key, granularity: granularity, showPhase: filter.showPhase, groups: filter.groups });
      // Array para almacenar promesas de fetch
      const fetchPromises = [];
      //recorre grupos
      filter.groups.forEach((group, groupIndex) => {
        const year = group.selectedDate.getFullYear();
        const month = group.selectedDate.getMonth() + 1;
        group?.datasets.forEach((dataset, datasetIndex) => {
          if (dataset && Object.keys(dataset).length > 0) {
            // Arma URL para obtener data de endpoint
            let url = '';
            let selectedNodeId = dataset?.devices[0]?.id;
            let selectedTagId = dataset?.devices[0]?.label_id;

            // Asigna dispositivo con etiqueta si se seleccionó
            url += `filter=${selectedNodeId}`;
            if (selectedTagId) {
              url += `+${selectedTagId}`;
              let nameDevice = `${dataset?.devices[0]?.nombre} ${dataset?.devices[0]?.label_nombre}`
              devices.push(nameDevice)
            } else {
              let nameDevice = `${dataset?.devices[0]?.nombre}`
              devices.push(nameDevice)
            }
            // Asigna variable
            url += `&fields=${variable?.fields}`;
            // Asigna año y mes
            if (filter.granularity === 'DAILY') {
              url += `&year=${year}&month=${month}`;
            } else if (filter.granularity === 'MONTHLY') {
              url += `&year=${year}`;
            }
            // Asigna periodo/granularidad
            url += `&period=${granularity}`;
            url += `&response_format=v2`;
            // Asigna franja horaria
            url += dataset.timeslots ? `&timeslots=${dataset.timeslots}` : '';
            // Si selecciono variable por fase la asigna
            url += filter.showPhase ? `&showPhase=true` : '';
            // Agrega la promesa a la lista, incluyendo los índices
            fetchPromises.push(
              fetchHistory(
                url,
                dataset.alias,
                groupIndex,
                datasetIndex,
                variable,
                dataset?.color
              )
            );
          }
        });
      });

      setLoading(true);
      try {
        const results = await Promise.all(fetchPromises);
        const successfulResults = results.filter(data => data !== null);
        setDevicesNames(devices)
        if (successfulResults.length === 0) {
          // Todas las solicitudes fallaron
          setErrorMessage('Ha ocurrido un error');
          setShowErrorAlert(true);
          setHistoricalData([]);
        } else {
          // Mezclar los datos exitosos
          const mergedData = successfulResults.reduce((acc, data) => acc.concat(data), []);
          setHistoricalData(mergedData);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
        setHistoricalData([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchHistory = async (
    url,
    alias,
    groupIndex,
    datasetIndex,
    variable,
    color
  ) => {
    //hace esto por cada dataset
    try {
      const res = await getHistoryV2(url);
      if (res.data.error) {
        return []; // Retorna un array vacío si hay error
      } else {
        setShowErrorAlert(false);
        let data = res.data[0]?.data;
        let resData = [];
        let timestampField = `timestamp${groupIndex + 1}`
        if ((variable?.key !== "ENERGIA_ACTIVA_POR_FASE") && (variable?.key !== "ENERGIA_REACTIVA_POR_FASE")) {

          // Agregar el índice del grupo y del dataset a cada entrada en res.data
          resData = data[variable?.fields]?.map(entry => ({
            ...entry,
            groupIndex, // Índice del grupo
            datasetIndex, // Índice del dataset
            alias, // Alias del dataset
            color,
            [timestampField]: entry.timestamp,
          }));

        } else {
          // Recorriendo las propiedades del objeto arrayData
          resData = Object.entries(data).flatMap(([key, fieldArray]) => {
            return fieldArray.map(entry => ({
              ...entry,
              groupIndex, // Índice del grupo
              datasetIndex, // Índice del dataset
              alias, // Alias del dataset
              color,
              key,
              [timestampField]: entry.timestamp,
            }))
          }
          );
        }
        return resData; // Retorna los datos si no hay error
      }
    } catch (error) {
      setHistoricalData([]);
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage(
            `No se encontraron datos para el período ${alias}.`
          );
        } else {
          setErrorMessage(`El dataset ${alias} devolvió un error.`);
        }
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
      setShowErrorAlert(true);
      return []; // Retorna un array vacío en caso de error
    }
  };

  const handleFilterClick = () => {
    setFilterPanel(!filterPanel);
  };

  const decrementMonths = () => {
    let filter = { ...filterOptions };
    filter.groups = filter.groups.map(group => {
      const selectedDate = new Date(group.selectedDate);
      selectedDate.setMonth(selectedDate.getMonth() - 1);
      group.selectedDate = selectedDate;
      return group;
    });
    setFilterOptions(filter);
    handleSubmit(filter);
  }

  const decrementYears = () => {
    let filter = { ...filterOptions };
    filter.groups = filter.groups.map(group => {
      const selectedDate = new Date(group.selectedDate);
      selectedDate.setFullYear(selectedDate.getFullYear() - 1);
      group.selectedDate = selectedDate;
      return group;
    });
    setFilterOptions(filter);
    handleSubmit(filter);
  }

  const incrementMonths = () => {
    let filter = { ...filterOptions };
    filter.groups = filter.groups.map(group => {
      const selectedDate = new Date(group.selectedDate);
      selectedDate.setMonth(selectedDate.getMonth() + 1);
      group.selectedDate = selectedDate;
      return group;
    });
    setFilterOptions(filter);
    handleSubmit(filter);
  }

  const incrementYears = () => {
    let filter = { ...filterOptions };
    filter.groups = filter.groups.map(group => {
      const selectedDate = new Date(group.selectedDate);
      selectedDate.setFullYear(selectedDate.getFullYear() + 1);
      group.selectedDate = selectedDate;
      return group;
    });
    setFilterOptions(filter);
    handleSubmit(filter);
  }

  const isFuture = () => {
    const largestDate = filterOptions.groups.reduce((maxDate, group) => {
      const groupDate = new Date(group.selectedDate);
      return groupDate > new Date(maxDate) ? group.selectedDate : maxDate;
    }, new Date(0));
       
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    if (filterOptions.granularity === "DAILY") {
      return (
        largestDate?.getMonth() + 1 === currentMonth && largestDate?.getFullYear() === currentYear
      );
    }
    if (filterOptions.granularity === "MONTHLY") {
      return largestDate?.getFullYear() === currentYear;
    }
    return false;
  }

  return (
    <>
      <TopNav
        titulo="Análisis"
        subtitulo="| Históricos avanzado"
        changeTitleStyleFlex={true}
      >
        <Grid container justifyContent="flex-end">

          {filterOptions.groups.some(group => group.datasets.length > 0) && (
            filterOptions.granularity !== 'YEARLY' ? (
              <Box
                className={classes.dateContainer}
                display="flex"
                justifyContent="center"
              >
                <IconButton
                  aria-label="Período anterior"
                  onClick={
                    filterOptions.granularity === 'DAILY'
                      ? decrementMonths
                      : decrementYears
                  }
                  disabled={loading}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                  {filterOptions.groups.map((group, groupIndex) => {
                    if (group.datasets?.length > 0) {
                      return (
                        <Box>
                          {filterOptions.granularity === 'DAILY' ? (
                            formatterMonthly.format(group.selectedDate)
                          ) : formatterYearly.format(group.selectedDate)}
                        </Box>
                      )
                    } else {
                      return null
                    }
                  })}
                </Box>
                <IconButton
                  aria-label="Período siguiente"
                  // Si la granularidad es 'DAILY' incremento meses, si es 'MONTHLY' incremento años (si es año no se verá este componente)
                  onClick={
                    filterOptions.granularity === 'DAILY'
                      ? incrementMonths
                      : incrementYears
                  }
                  className={classes.nextPeriod}
                 disabled={isFuture()}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            ) : (
              <Box
                className={classes.dateContainer}
                display="flex"
                justifyContent="center"
                alignItems="center"
                mx={2}
              >
                <p>Acumulado Histórico</p>
              </Box>
            )

          )}

          <Button
            className={classes.btn}
            type="button"
            onClick={handleFilterClick}
            size="small"
            startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
          >
            | Filtrar
          </Button>

          {/* Boton refresh */}
          <Tooltip title="Última consulta." arrow>
            <Button
              className={classes.btnRefresh}
              type="button"
              onClick={() => handleSubmit(filterOptions)}
              startIcon={<FlipCameraAndroidIcon />}
            ></Button>
          </Tooltip>
        </Grid>
      </TopNav>

      <Dialog
        open={filterPanel}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        disableScrollLock
        onClose={handleFilterClick}
      >
        <Filter
          filterOptions={filterOptions}
          setFilterPanel={setFilterPanel}
          onSubmit={handleSubmit}
        />
      </Dialog>

      <Container>
        {showErrorAlert && <Alert severity="error">{errorMessage}</Alert>}
        {loading ? (
          <div className={classes.circular}>
            <CircularProgress />
          </div>
        ) : (historicalData.length > 0) ? (
          <Box>
            <Box className={classes.containerChips}>
              <p>Dispositivos a graficar:</p>
              {devicesNames?.map(device => (
                <Chip
                  className={classes.chip}
                  key={device.alias}
                  icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                  label={`${device}`}
                  clickable
                  color="primary"
                  onClick={handleFilterClick}
                />
              ))}
              <Chip
                className={classes.chip}
                label={
                  <Box component="span">
                    <strong>Variable:</strong>{' '}
                    {nameVariableSelected}
                  </Box>
                }
                color="secondary"
                variant="outlined"
                clickable
                onClick={() => setFilterPanel(true)}
              />
            </Box>
            <Box p={3}>
              <ChartV2Advanced
                chartData={historicalData}
                chartOptions={filterOptions}
                height={'70vh'}
                showLegend={true}
              />
            </Box>
          </Box>
        ) :
          <Alert className={classes.alert} severity="info">
            Bienvenido a Históricos avanzados. En esta sección podrá consultar la
            información de consumos acumulados de energía de sus dispositivos formando grupos.
            Para comenzar haga click en el botón
            <span className={classes.tuneIcon}>
              <TuneIcon />
            </span>
            , ingrese los parámetros y haga click en CONSULTAR.
          </Alert>}

      </Container>
    </>
  );
}
