import React, { useEffect, useState } from 'react';
//Material ui.
import {
    makeStyles, Box, Button
} from '@material-ui/core';
//componente
import TopNav from '../common/TopNav';
//Componentes
import Container from '../Elements/Container';
import LoadingComponentBlur from '../common/LoadingComponentBlur';
//Helpers
import { blueBar } from '../../helpers/common'
import { configBlocks } from './helpers';
// custom hooks
import useDeviceDetect from '../../hooks/useDeviceDetect';

const useStyles = makeStyles(theme => ({
    whatsappButton: {
        backgroundColor: '#4CAF50',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
    },
    partnerButton: {
        textTransform: 'none',
    },
    containerBlock: {
        width: '35%',
        display: 'flex',
        marginTop: '20px',
        marginBottom: '20px',
        justifyContent: 'center',
        '@media (max-width: 1300px)': {
            width: '40%',
        },
        '@media (max-width: 1100px)': {
            width: '100%',
        },
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '70%',
        padding: '10px',
        fontSize: '15px',
    },
    iframeContainerStyle: {
        overflow: 'hidden',
        width: '100%',
        height: '800px',
    },
    containerIframe: {
        width: '1000px',
        '@media (max-width: 900px)': {
            width: '500px',
        },
    }
}))

export default function Assistant() {
    const classes = useStyles()
    const blueLine = blueBar()
    const [loadingBlurState, setLoadingBlurState] = useState(true)
    const { isMobile } = useDeviceDetect(920);
    const iframeStyle = {
        width: '100%',
        height: '2000px',
        border: 'none',
        transformOrigin: '0 0',
        transform: isMobile ? 'scale(1)' : '',
    };

    useEffect(() => {
        setTimeout(() => {
            setLoadingBlurState(false)
        }, [500])
    }, [])

    const handleRedirect = () => {
        window.open("https://www.powermeter.com.ar/Formularios/formulario-assistant", "_blank");
    };

    const handleRedirectWhatsapp = () => {
        window.open("https://api.whatsapp.com/send/?phone=5491176514417&text&type=phone_number&app_absent=0", "_blank");
    };

    return (
        <>
            <TopNav titulo="Powermate" subtitulo='| Assistant' />

            {/* Container loading */}
            <LoadingComponentBlur
                loadingState={loadingBlurState}
            />

            {!loadingBlurState &&
                <Container >
                    <Box p={3} width={'100%'} display='flex' justifyContent='center' flexWrap={'wrap'} fontSize='35px' fontWeight={'bold'} >
                        <Box mr={1} color={'#091255'}>¿Qué es</Box>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                            <Box component="span" color={'#01d4cd'}>Power</Box>
                            <Box component="span" mr={1} color={'#2980b9'}>mate</Box>
                        </Box>
                        <Box color={'#091255'}>Assistant?</Box>                </Box>
                    <Box width={'100%'} display='flex' justifyContent={'center'} >
                        <Box className={classes.description}>
                            <Box>Powermate Assistant es la vertical de negocios de Powermeter orientada a servicios de <strong style={{ color: '#2980b9' }}>consultoría de eficiencia energética</strong>.</Box>
                            <Box>Nuestra propuesta se sustenta en 3 pilares:</Box>
                            <ul style={{ listStyleType: 'none' }}>
                                <li>Provisión de <strong style={{ color: '#2980b9' }}>soluciones tecnológicas</strong> para la gestión de la energía basadas en IoT, Industria 4.0 e inteligencia artificial.</li>
                                <li><strong style={{ color: '#2980b9' }}>Red de partners</strong> oficial conformada por auditores energéticos, consultoras y proveedores tecnológicos especializados.</li>
                                <li><strong style={{ color: '#2980b9' }}>Capacitación</strong> continua de profesionales calificados para la gestión de proyectos de eficiencia energética.</li>
                            </ul>
                        </Box>
                    </Box>

                    <Box style={{ paddingLeft: '15px', paddingRight: '15px' }}  >
                        {blueLine}
                    </Box>

                    <Box p={3} width={'100%'} display='flex' justifyContent='center' flexWrap={'wrap'} fontSize='35px' fontWeight={'bold'} >
                        <Box mr={1} color={'#091255'}>Servicios asociados</Box>
                    </Box>
                    <Box width={'100%'} display='flex' justifyContent='center' flexWrap={'wrap'}>

                        {configBlocks.map((element) => {
                            return (
                                <Box className={classes.containerBlock}  >
                                    <Box width={'85%'} >
                                        <Box display={'flex'} >
                                            <Box width={'100%'} display='flex' justifyContent='center' fontSize='25px' fontWeight={'bold'} > <Box mr={2}>{element?.icon}</Box>{element?.title}</Box>
                                        </Box>
                                        <Box fontSize='15px' >
                                            {element?.items}
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>

                    <Box style={{ paddingLeft: '15px', paddingRight: '15px' }}  >
                        {blueLine}
                    </Box>

                    <Box p={3} width={'100%'} display='flex' justifyContent='center' alignItems={'center'} flexDirection={'column'} fontSize='35px' fontWeight={'bold'} >
                        <Box mr={1} color={'#091255'}>Contacto</Box>
                        <Box fontSize='25px' fontWeight={'bold'} >
                            Si necesitas asistencia con tus consumos de energía, contáctanos o escríbenos.
                        </Box>
                    </Box>

                    <Box width={'100%'} display='flex' justifyContent={'center'}>
                        <Box style={{ padding: '5px 8px 5px 8px' }} width={'200px'} >
                            <Button
                                onClick={handleRedirectWhatsapp}
                                fullWidth size="small" variant="contained"
                                className={classes.whatsappButton}
                            >
                                WhatsApp
                            </Button>
                        </Box>
                        <Box style={{ padding: '5px 8px 5px 8px' }} width={'200px'} >
                            <Button
                                className={classes.partnerButton}
                                onClick={handleRedirect}
                                fullWidth size="small" variant="contained" color="primary" >
                                Quiero ser partner
                            </Button>
                        </Box>
                    </Box>

                    <Box display={'flex'} width={'100%'} justifyContent={'center'} mt={2}>
                        <Box className={classes.containerIframe}>
                            <iframe
                                src="https://forms.zohopublic.com/powermeter/form/ContactForm/formperma/YZzWSYmH0TPK5ZZcbQaGRiq4-A4TnFDaVTIu21-S8_E"
                                style={iframeStyle}
                                title="Contact Form"
                            ></iframe>
                        </Box>
                    </Box>

                </Container >}
        </>
    )
}

