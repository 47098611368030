import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { blueBar } from '../../helpers/common';
import { debounce } from 'lodash';
import SelectNodeLabel from '../InstantAdvanced/SelectNodeLabel';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { getExtraInfo } from '../../services/hierarchy';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiGrid-root': {
      padding: theme.spacing(1),
    },
    steppersFilter: {
      '&.MuiStep-horizontal': {
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  },
  label: {
    marginLeft: theme.spacing(3),
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(1),
      marginBottom: '5px',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  wrapperDatepicker: {
    marginTop: '20px',
    position: 'relative',
    width: '100%',
    left: '50%',
    transform: 'translate(-50%)',
    textAlign: 'center',
  },
  labelDate: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5)
  },
}));

export default function DatasetFilter({
  setShowDatasetFilter,
  datasetSelected,
  setMainObj,
  mainObj,
  indexGroupselected,
  indexDataSetSelected,
  setActiveStepMainFilter
}) {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect(990);
  const steps = ['Elija un dispositivo', 'Seleccione un alias', 'Seleccione un color', 'Seleccione una franja horaria (Opcional)'];
  const blueLine = blueBar();
  const [dataset, setDataset] = useState(datasetSelected);
  const [selectedDays, setSelectedDays] = useState([]);
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const initialDates = {
    dateFrom: thirtyDaysAgo,
    dateTo: today,
  };
  const [selectedHoursTimeSlot, setHoursTimeSlot] = useState({
    time_from: '00:00',
    time_to: '23:59',
    step: 900,
  });
  const [filter, setFilter] = useState(datasetSelected?.date || initialDates);
  const [disabledTimeSlot, setDisabledTimeSlot] = useState(false);
  const [showDevices, setShowDevices] = useState(false);
  const [nodeLabelSelected, setNodeLabelSelected] = useState({});
  const [activeStep, setActivestep] = useState(0);

  useEffect(() => {
    getTimeSlotsValues();
    calculateActiveStep()
  }, []);

  useEffect(() => {
    const buildTimeSlot = () => {
      const timeslots = `${selectedDays}=${selectedHoursTimeSlot?.time_from}a${selectedHoursTimeSlot?.time_to}`;
      setDataset(prevDataset => ({
        ...prevDataset,
        timeslots: timeslots,
      }));
    };
    buildTimeSlot();
  }, [selectedDays, selectedHoursTimeSlot]);

  useEffect(() => {
    if (nodeLabelSelected?.selectedNodes) {
      let devices = nodeLabelSelected?.selectedNodes
      if (nodeLabelSelected?.selectedNodes.length === 1) {
        getExtraInfoNode();
      }
      if (nodeLabelSelected.selectedLabel) {
        let { id, nombre } = nodeLabelSelected.selectedLabel
        devices[0].label_id = id
        devices[0].label_nombre = nombre
      }
      if (!dataset.devices) {
        setActivestep(3);
      }
      setDataset(prevDataset => ({
        ...prevDataset,
        devices,
        color: devices?.length === 1 ? devices[0]?.color : '',
        alias: devices?.length === 1 ? devices[0]?.nombre : ''
      }));
    }
  }, [nodeLabelSelected]);

  const getExtraInfoNode = async () => {
    let extraInfo = await getExtraInfo(nodeLabelSelected?.selectedNodes[0].id);
    if (extraInfo?.data?.workhours?.length > 0) {
      setSelectedDays(extraInfo?.data?.workhours[0]?.days);
      let time_from = extraInfo?.data?.workhours[0]?.hours[0].from;
      let time_to = extraInfo?.data?.workhours[0]?.hours[0].to;
      setHoursTimeSlot({
        time_from,
        time_to,
      });
      setDisabledTimeSlot(false)
    }else{
      setSelectedDays([])
      setHoursTimeSlot({
        time_from: '00:00',
        time_to: '23:59',
      })
      setDisabledTimeSlot(true)
    }
  }

  useEffect(() => {
    const buildFilterObject = () => {
      setDataset(prevDataset => ({
        ...prevDataset,
        date: {
          dateFrom: filter.dateFrom,
          dateTo: filter.dateTo,
        },
      }));
    };

    if (filter.dateFrom && filter.dateTo) {
      buildFilterObject();
    }
  }, [filter.dateFrom, filter.dateTo]);

  useEffect(() => {
    const buildTimeSlot = () => {
      const timeslots = `${selectedDays}=${selectedHoursTimeSlot?.time_from}a${selectedHoursTimeSlot?.time_to}`;
      setDataset(prevDataset => ({
        ...prevDataset,
        timeslots: disabledTimeSlot ? '' : timeslots,
      }));
    };

    buildTimeSlot();
  }, [selectedDays, selectedHoursTimeSlot, disabledTimeSlot]);

  const getTimeSlotsValues = () => {
    if (datasetSelected?.timeslots) {
      setDisabledTimeSlot(false);
      let timeslots = datasetSelected?.timeslots;
      let elements = timeslots?.split('=')[0];
      // Separa los elementos por coma
      let days = elements.split(',');
      setSelectedDays(days);
      // Separa la parte después del '='
      let hours = timeslots.split('=')[1];
      // Separa los tiempos por la 'a'
      let [time_from, time_to] = hours.split('a');

      setHoursTimeSlot({
        time_from,
        time_to,
      });
    } else {
      setDisabledTimeSlot(true);
    }
  };

  const handleSubmit = () => {
    if (dataset?.devices?.length > 1) {
      handleMultiplesDevices();
    } else {
      mainObj.groups[indexGroupselected].datasets[indexDataSetSelected] = dataset;
      setMainObj(mainObj);
    }
    setShowDatasetFilter(false);
    if (indexGroupselected === 0) {
      setActiveStepMainFilter(3)
    } else if (indexGroupselected === 1) {
      setActiveStepMainFilter(4)
    } else if (indexGroupselected === 2) {
      setActiveStepMainFilter(5)
    }
  };

  const handleMultiplesDevices = () => {
    let devices = [...dataset.devices]
    let datasets = devices.map((device) => ({
      devices: [device],
      alias: device.nombre,
      color: device.color,
      timeslots: dataset.timeslots
    }))
    mainObj.groups[indexGroupselected].datasets.push(...datasets)
  }

  const handleAlias = e => {
    setDataset({
      ...dataset,
      alias: e?.target?.value,
    });
    if (!dataset.alias) {
      setActivestep(1);
    }
  };

  const handleColorChange = debounce(value => {
    setDataset({
      ...dataset,
      color: value,
    });
    setActivestep(3);
  });

  const handleTimeSlot = event => {
    const { value, checked } = event.target;
    if (checked) {
      // Si se selecciona, agregamos el día al array
      setSelectedDays([...selectedDays, value]);
    } else {
      // Si se deselecciona, eliminamos el día del array
      setSelectedDays(selectedDays.filter(day => day !== value));
    }
  };

  const handleOpenCloseSelectNodeTag = () => {
    setShowDevices(!showDevices);
  };

  const handleHoursTimeSlot = event => {
    const { value, name } = event.target;
    setHoursTimeSlot({
      ...selectedHoursTimeSlot,
      [name]: value,
    });
  };

  const handleCancel = () => {
    setShowDatasetFilter(false);
  };

  const handleDeleteDevice = (index) => {
    let devices = [...dataset.devices]
    devices.splice(index, 1)
    setDataset({
      ...dataset,
      devices: devices,
    });
  };

  function calculateActiveStep() {
    if (datasetSelected?.timeslots) {
      setActivestep(4);
    } else if (dataset?.color) {
      setActivestep(3);
    } else {
      setActivestep(0);
    }
  }

  return (
    <>
      <Stepper
        className={classes.steppersFilter}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{isMobile ? '' : label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {blueLine}

      <form>
        <Grid container style={{ padding: 8 }}>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Tooltip
                title="Elija el/los dispositivos que desea añadir al gráfico haciendo click en el botón +"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 0 },
                    { [classes.tick]: dataset?.devices }
                  )}
                >
                  1
                </Box>
              </Tooltip>
              <Box className={classes.devicesLabel}>Agregar dispositivo</Box>
              <IconButton
                className={classes.addBtn}
                onClick={() => {
                  setShowDevices(true);
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>

              {dataset?.devices?.map((node, index) =>
              (
                <Chip
                  key={node.id}
                  icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                  label={`${node.nombre}
                          ${node.label_nombre ? ` + ${node.label_nombre}` : ''
                    }`}
                  clickable
                  color="primary"
                  style={{ marginLeft: '5px' }}
                  onClick={() => handleOpenCloseSelectNodeTag('edit')}
                  onDelete={() => handleDeleteDevice(index)}
                />
              )
              )}
              {(!dataset.devices || dataset?.devices?.length === 0) && <Box ml={1} mt={1} className={classes.devicesLabel} width={'100%'}>Debe seleccionar al menos un dispositivo.</Box>}
              <Box ml={1} mt={1} className={classes.devicesLabel} width={'100%'}>Nota: Si selecciona múltiples dispositivos se creará un dataset para cada uno.</Box>
            </Box>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Box display="flex" alignItems="center" flexWrap="wrap" mt={3}>
              <Tooltip title="Alias" arrow placement="right">
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 1 },
                    { [classes.tick]: dataset?.alias }
                  )}
                >
                  2
                </Box>
              </Tooltip>
              <TextField
                autoFocus
                placeholder="Alias"
                name="name"
                onChange={e => {
                  handleAlias(e);
                }}
                value={dataset?.alias || ''}
                color="primary"
                disabled={dataset?.devices?.length > 1 || (!dataset.devices || dataset.devices.length === 0)}
              />
            </Box>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Box display="flex" alignItems="center" flexWrap="wrap" mt={3}>
              <Tooltip title="Color" arrow placement="right">
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 2 },
                    { [classes.tick]: activeStep > 2 || dataset?.color }
                  )}
                >
                  3
                </Box>
              </Tooltip>
              <Box className={classes.devicesLabel}>Seleccione un color</Box>
              <TextField
                style={{ width: '40px', marginLeft: '15px' }}
                type="color"
                value={dataset?.color || ''}
                name="color1"
                onChange={e => handleColorChange(e.target.value)}
                disabled={dataset?.devices?.length > 1 || (!dataset.devices || dataset.devices.length === 0)}
              />
            </Box>
          </Grid>

          <Grid item xs={12} mt={1}>
            <Box display="flex" alignItems="start" flexWrap="wrap" mt={2}>
              <Tooltip title="Franja horaria" arrow placement="right">
                <Box
                  marginTop={1}
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 3 },
                    { [classes.tick]: selectedDays?.length > 0 }
                  )}
                >
                  4
                </Box>
              </Tooltip>
              <Box display={'flex'} alignItems={'center'}>
                <Box className={classes.devicesLabel} mr={1}>Franja horaria</Box>
                <FormControlLabel
                  control={
                    <Switch
                      name="showSecondary"
                      checked={!disabledTimeSlot}
                      onChange={() => setDisabledTimeSlot(!disabledTimeSlot)}
                      color="primary"
                      disabled={!dataset.devices || dataset.devices.length === 0}
                    />
                  }
                />
              </Box>
            </Box>
            <Box ml={2}>
              <Box>
                <FormControl disabled={disabledTimeSlot}>
                  <FormGroup row>
                    {['lu', 'ma', 'mi', 'ju', 'vi', 'sa', 'do'].map(elem => (
                      <FormControlLabel
                        key={elem}
                        control={
                          <Checkbox
                            value={elem}
                            checked={selectedDays.includes(elem)}
                            onChange={handleTimeSlot}
                            color="primary"
                          />
                        }
                        label={elem}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Box>

              <Box mt={1} display={'flex'}>
                {/* DESDE */}
                <Box>
                  <TextField
                    name="time_from"
                    disabled={disabledTimeSlot}
                    type="time"
                    label="Desde:"
                    size="small"
                    variant="outlined"
                    value={selectedHoursTimeSlot?.time_from}
                    onChange={e => handleHoursTimeSlot(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: selectedHoursTimeSlot?.step,
                      pattern: '[0-9]{2}:[0-9]{2}',
                    }}
                    onInput={e => {
                      const input = e.target;
                      let value = input.value;
                      // Solo bloquear los minutos si el step es 3600 (1 hora)
                      if (selectedHoursTimeSlot?.step === 3600 && value) {
                        const [hours] = value.split(':');
                        input.value = `${hours}:00`; // Fija los minutos a 00
                        handleHoursTimeSlot(e); // Llama a la función de manejo
                      } else {
                        // Si el step no es 3600, permitir la modificación normal
                        handleHoursTimeSlot(e);
                      }
                    }}
                  />
                </Box>
                {/* HASTA */}
                <Box ml={2}>
                  <TextField
                    name="time_to"
                    disabled={disabledTimeSlot}
                    type="time"
                    label="Hasta:"
                    size="small"
                    variant="outlined"
                    value={selectedHoursTimeSlot?.time_to}
                    onChange={e => handleHoursTimeSlot(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: selectedHoursTimeSlot?.step,
                      pattern: '[0-9]{2}:[0-9]{2}',
                    }}
                    onInput={e => {
                      const input = e.target;
                      let value = input.value;

                      // Solo bloquear los minutos si el step es 3600 (1 hora)
                      if (selectedHoursTimeSlot?.step === 3600 && value) {
                        const [hours] = value.split(':');
                        input.value = `${hours}:00`; // Fija los minutos a 00
                        handleHoursTimeSlot(e); // Llama a la función de manejo
                      } else {
                        // Si el step no es 3600, permitir la modificación normal
                        handleHoursTimeSlot(e);
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {blueLine}

        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
            disabled={((dataset?.devices?.length === 1) && (!dataset?.devices || !dataset?.alias)) || (!dataset?.devices || dataset?.devices.length === 0)}
          >
            Aceptar
          </Button>

          <Button
            variant="contained"
            onClick={handleCancel}
            className={classes.btn}
          >
            Cancelar
          </Button>
        </Box>
      </form>
      {showDevices && (
        <SelectNodeLabel
          showDevices={showDevices}
          setNodeLabelSelected={setNodeLabelSelected}
          handleOpenCloseSelectNodeTag={handleOpenCloseSelectNodeTag}
          multi={true}
        />
      )}
    </>
  );
}
