import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';

export default function ChartV2Advanced({
  chartData,
  chartOptions,
  height,
  ky,
  dashboard,
  showLegend = false,
}) {
  const groupData = () => {
    const groupedData = {};
    let datasets = [];
    chartData.forEach(item => {
      let groupKey = "";
      groupKey = `${item.groupIndex}-${item.datasetIndex}`;
      // Si el grupo no existe en groupedData, lo inicializamos como un array vacío
      if (!groupedData[groupKey]) {
        groupedData[groupKey] = [];
      }
      // Añadimos una propiedad groupArrayIndex para almacenar el índice dentro del grupo
      const groupArrayIndex = groupedData[groupKey].length;
      groupedData[groupKey].push({ ...item, groupArrayIndex });
    });

    datasets = Object.entries(groupedData).map(([key, value]) => ({
      groupKey: key,
      data: value,
    }));
    return datasets;
  };

  useEffect(() => {
    const { variable, granularity } = chartOptions;
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(`historicos${ky}`, am4charts.XYChart);
    let snapToSeries = []

    if (chart.logo) {
      chart.logo.disabled = true;
    }
    chart.fontSize = 11;
    chart.language.locale = am4lang_es_ES;

    if (!dashboard) {
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.adapter.add('data', function (data, target) {
        const myData = [];
        chart.series.each(function (series) {
          for (var i = 0; i < series.data.length; i++) {
            series.data[i].name = series.name;
            myData.push(series.data[i]);
          }
        });
        return { data: myData };
      });
      // Modifico el menu de exportación
      chart.exporting.menu.items = [
        {
          label: '...',
          menu: [
            { type: 'pdf', label: 'PDF' },
            { type: 'csv', label: 'CSV' },
            { type: 'xlsx', label: 'XLSX' },
            { type: 'print', label: 'Imprimir' },
          ],
        },
      ];
    }
    if (showLegend) {
      chart.legend = new am4charts.Legend();
    }

    chart.hiddenState.properties.opacity = 0;

    let dataseries = null;
    if (granularity === 'DAILY') {
      dataseries = fillDaysForMonth(chartData);
    } else {
      dataseries = chartData;
    }

    chart.data = dataseries;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = 'timestamp1';

    const categoryAxis2 = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis2.renderer.grid.template.location = 0;
    categoryAxis2.dataFields.category = 'timestamp2';

    const categoryAxis3 = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis3.renderer.grid.template.location = 0;
    categoryAxis3.dataFields.category = 'timestamp3';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.extraMax = 0.1;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 20;

    let dataseries1 = dataseries.filter(item => item.hasOwnProperty('timestamp1'))
    let dataseries2 = dataseries.filter(item => item.hasOwnProperty('timestamp2'))
    let dataseries3 = dataseries.filter(item => item.hasOwnProperty('timestamp3'))

    if (dataseries3?.length > 0) {
      resizeCells(0.07, 0.42, 0.32, 0.67, 0.57, 0.90)
    } else if (dataseries2?.length > 0) {
      resizeCells(0.06, 0.58, 0.42, 0.94)
    } else if (dataseries1?.length > 0) {
      resizeCells(0.05, 0.95)
    }

    chart.seriesContainer.zIndex = 10;
    let datasets = groupData();
    let lastIndexGroup;

    switch (variable) {
      case 'POTENCIA_ADQUIRIDA_MAXIMA':
        valueAxis.title.text = 'Potencia adquirida maxima';
        chart.numberFormatter.numberFormat = '#.##aw';
        addSeriesDefaultMethod(true);
        chart.data = multiplyData();
        break;
      case 'ENERGIA_REACTIVA_TOTAL':
        valueAxis.title.text = 'Energía Reactiva';
        chart.numberFormatter.numberFormat = '#.##aVar';
        addSeriesDefaultMethod(true);
        chart.data = multiplyData();
        break;
      case 'COS_(FI)_TOTAL':
        valueAxis.title.text = 'cos_fi';
        chart.numberFormatter.numberFormat = '#.##';
        addSeriesDefaultMethod(false);
        break;
      case 'TAN_(FI)_TOTAL':
        valueAxis.title.text = 'tan_fi';
        chart.numberFormatter.numberFormat = '#.##';
        addSeriesDefaultMethod(false);
        break;
      case 'ENERGIA_ACTIVA_POR_FASE':
        valueAxis.title.text = 'Energía Activa';
        chart.numberFormatter.numberFormat = '#.##aWh';
        for (let index = 0; index < datasets.length; index++) {
          let kwh_r_data = datasets[index].data
            .filter(item => item.key === 'kwh_r')
            .map(item => ({
              ...item,
              valor: item.valor * 1000,
            }));
          let kwh_s_data = datasets[index].data
            .filter(item => item.key === 'kwh_s')
            .map(item => ({
              ...item,
              valor: item.valor * 1000,
            }));
          let kwh_t_data = datasets[index].data
            .filter(item => item.key === 'kwh_t')
            .map(item => ({
              ...item,
              valor: item.valor * 1000,
            }));

          if (lastIndexGroup !== datasets[index].data[0].groupIndex) {
            addSeries(
              kwh_r_data,
              `Fase R(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 1),
              false
            );
            addSeries(
              kwh_s_data,
              `Fase S(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.75),
              true
            );
            addSeries(
              kwh_t_data,
              `Fase T(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.5),
              true
            );
          } else {
            addSeries(
              kwh_r_data,
              `Fase R(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 1),
              true
            );
            addSeries(
              kwh_s_data,
              `Fase S(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.75),
              true
            );
            addSeries(
              kwh_t_data,
              `Fase T(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.5),
              true
            );
          }
          lastIndexGroup = datasets[index].data[0].groupIndex;
        }
        break;
      case 'ENERGIA_REACTIVA_POR_FASE':
        valueAxis.title.text = 'Energía Reactiva';
        chart.numberFormatter.numberFormat = '#.##aVar';
        for (let index = 0; index < datasets.length; index++) {
          let kvarh_r_data = datasets[index].data
            .filter(item => item.key === 'kvarh_r')
            .map(item => ({
              ...item,
              valor: item.valor * 1000,
            }));
          let kvarh_s_data = datasets[index].data
            .filter(item => item.key === 'kvarh_s')
            .map(item => ({
              ...item,
              valor: item.valor * 1000,
            }));
          let kkvarh_t_data = datasets[index].data
            .filter(item => item.key === 'kvarh_t')
            .map(item => ({
              ...item,
              valor: item.valor * 1000,
            }));

          if (lastIndexGroup !== datasets[index].data[0].groupIndex) {
            addSeries(
              kvarh_r_data,
              `Fase R(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 1),
              false
            );
            addSeries(
              kvarh_s_data,
              `Fase S(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.75),
              true
            );
            addSeries(
              kkvarh_t_data,
              `Fase T(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.5),
              true
            );
          } else {
            addSeries(
              kvarh_r_data,
              `Fase R(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 1),
              true
            );
            addSeries(
              kvarh_s_data,
              `Fase S(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.75),
              true
            );
            addSeries(
              kkvarh_t_data,
              `Fase T(${datasets[index].data[0].alias})`,
              adjustColorOpacity(datasets[index].data[0].color, 0.5),
              true
            );
          }
          lastIndexGroup = datasets[index].data[0].groupIndex;
        }
        break;
      case 'ENERGIA_ACTIVA_IMPORTADA_TOTAL':
        valueAxis.title.text = 'Energía activa importada total';
        chart.numberFormatter.numberFormat = '#.##aWh';
        addSeriesDefaultMethod(false);
        break;
      case 'ENERGIA_ACTIVA_EXPORTADA_TOTAL':
        valueAxis.title.text = 'Energía activa exportada total';
        chart.numberFormatter.numberFormat = '#.##aWh';
        addSeriesDefaultMethod(false);
        break;
      case 'ENERGIA_ACTIVA_GENERADA_TOTAL':
        valueAxis.title.text = 'Energía activa generada total';
        chart.numberFormatter.numberFormat = '#.##aWh';
        addSeriesDefaultMethod(false);
        break;
      case 'ENERGIA_ACTIVA_CONSUMIDA_TOTAL':
        valueAxis.title.text = 'Energía activa consumida total';
        chart.numberFormatter.numberFormat = '#.##aWh';
        addSeriesDefaultMethod(false);
        break;
      case 'ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL':
        valueAxis.title.text = 'Energía activa autoconsumida total';
        chart.numberFormatter.numberFormat = '#.##aWh';
        addSeriesDefaultMethod(false);
        break;
      default:
        chart.numberFormatter.numberFormat = '#.##aWh';
        addSeriesDefaultMethod(true);
        chart.data = multiplyData();
        break;
    }

    function addSeries(serieData, serieName, serieColor, stacked = true) {
      //hay que mandar la data que contenga los valores correctos de dateX y valueY
      let dataseries1 = serieData.filter(item => item.hasOwnProperty('timestamp1'))
      let dataseries2 = serieData.filter(item => item.hasOwnProperty('timestamp2'))
      let dataseries3 = serieData.filter(item => item.hasOwnProperty('timestamp3'))

      if (dataseries1?.length > 0) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(60);
        series.dataFields.valueY = 'valor';
        series.dataFields.categoryX = 'timestamp1';
        series.xAxis = categoryAxis;
        series.name = serieName;
        series.stacked = stacked;
        series.data = dataseries1;
        series.stroke = am4core.color(serieColor);
        series.columns.template.fill = am4core.color(serieColor);
        series.tooltip.pointerOrientation = 'vertical';
        series.tooltipText = `{valueY.value} - ${serieName}`;
        series.tooltip.autoTextColor = false;
        series.legendSettings.labelText = serieName;
        series.legendSettings.valueText = '{valueY}';
        snapToSeries?.push(series);
      }

      if (dataseries2?.length > 0) {
        const series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.columns.template.width = am4core.percent(60);
        series2.dataFields.valueY = 'valor';
        series2.dataFields.categoryX = 'timestamp2';
        series2.fill = am4core.color(serieColor);
        series2.stroke = am4core.color(serieColor);
        series2.strokeWidth = 2;
        series2.name = serieName;
        series2.yAxis = valueAxis;
        series2.xAxis = categoryAxis2;
        series2.data = dataseries2;
        series2.stacked = stacked;
        series2.stroke = am4core.color(serieColor);
        series2.columns.template.fill = am4core.color(serieColor);
        series2.tooltip.pointerOrientation = 'vertical';
        series2.tooltipText = `{valueY.value} - ${serieName}`;
        series2.tooltip.autoTextColor = false;
        series2.legendSettings.labelText = serieName;
        series2.legendSettings.valueText = '{valueY}';
        snapToSeries?.push(series2);
      }

      if (dataseries3?.length > 0) {
        const series3 = chart.series.push(new am4charts.ColumnSeries());
        series3.columns.template.width = am4core.percent(60);
        series3.dataFields.valueY = 'valor';
        series3.dataFields.categoryX = 'timestamp3';
        series3.name = serieName;
        series3.yAxis = valueAxis;
        series3.xAxis = categoryAxis3;
        series3.fill = am4core.color(serieColor);
        series3.stroke = am4core.color(serieColor);
        series3.strokeWidth = 2;
        series3.stacked = stacked;
        series3.data = dataseries3;
        series3.stroke = am4core.color(serieColor);
        series3.columns.template.fill = am4core.color(serieColor);
        series3.tooltip.pointerOrientation = 'vertical';
        series3.tooltipText = `{valueY.value} - ${serieName}`;
        series3.tooltip.autoTextColor = false;
        series3.legendSettings.labelText = serieName;
        series3.legendSettings.valueText = '{valueY}';
        snapToSeries?.push(series3);
      }

    
    }

    function resizeCells(start1, end1, start2, end2, start3, end3) {
      categoryAxis.renderer.cellStartLocation = start1
      categoryAxis.renderer.cellEndLocation = end1
      categoryAxis2.renderer.cellStartLocation = start2
      categoryAxis2.renderer.cellEndLocation = end2
      categoryAxis3.renderer.cellStartLocation = start3
      categoryAxis3.renderer.cellEndLocation = end3
    }

    function fillDaysForMonth(data) {
      // Agrupar los datos por mes
      const groupedByMonth = {};
      data.forEach(item => {
        const [year, month] = item.timestamp.split('-').slice(0, 2);
        const monthKey = `${year}-${month}-${item.groupIndex}`;
        if (!groupedByMonth[monthKey]) groupedByMonth[monthKey] = [];
        groupedByMonth[monthKey].push(item);
      });
      // Generar todos los días faltantes
      const completedData = [];
      for (const [month, records] of Object.entries(groupedByMonth)) {
        const [year, monthNum] = month.split('-');
        // Obtener el primer registro para el mes, para obtener el alias y color
        const referenceRecord = records[0] || {};
        for (let day = 1; day <= 31; day++) {
          const dayStr = day.toString().padStart(2, '0');
          const timestamp = `${year}-${monthNum}-${dayStr}`;
          // Verificar si el día ya existe
          const existingRecord = records.find(r => r.timestamp === timestamp);
          if (existingRecord) {
            // Agregar el registro existente
            completedData.push(existingRecord);
          } else {
            const timestampProperty = `timestamp${referenceRecord.groupIndex + 1 || 0}`;
            // Agregar el nuevo objeto con alias y color del primer objeto del mes
            completedData.push({
              value: null,
              timestamp,
              groupIndex: 0,
              datasetIndex: 0,
              alias: referenceRecord.alias,
              color: referenceRecord.color,
              [timestampProperty]: timestamp
            });
          }
        }
      }
      return completedData;
    }

    function addSeriesDefaultMethod(multiplyValue) {
      for (let index = 0; index < datasets.length; index++) {
        let actualIndexGroup = datasets[index].data[0].groupIndex;
        let actualDataGroup = datasets[index].data[0];
        if (lastIndexGroup !== actualIndexGroup) {
          addSeries(
            datasets[index].data.map(item => ({
              ...item,
              valor: multiplyValue ? item.valor * 1000 : item.valor,
            })),
            actualDataGroup.alias,
            actualDataGroup.color,
            false
          );
        } else {
          addSeries(
            datasets[index].data.map(item => ({
              ...item,
              valor: multiplyValue ? item.valor * 1000 : item.valor,
            })),
            actualDataGroup.alias,
            actualDataGroup.color,
            true
          );
        }
        lastIndexGroup = actualDataGroup.groupIndex;
      }
    }

    function multiplyData() {
      return chart.data.map(item => ({
        ...item,
        valor: item.valor * 1000
      }))
    }

    // Función para ajustar la opacidad de un color
    function adjustColorOpacity(color, opacity) {
      if (color.startsWith('#')) {
        // Convierte de HEX a RGBA
        const bigint = parseInt(color.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }
      if (color.startsWith('rgb')) {
        // Convierte RGB a RGBA
        return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
      }
      // Devuelve el color original si no se puede procesar
      return color;
    }

    chart.strokeWidth = 1;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.snapToSeries = chart._series?._values    
    return () => {
      chart.dispose();      
    };
    // eslint-disable-next-line
  }, [chartData, chartOptions]);

  const marginT = dashboard => {
    if (!dashboard) {
      return '14px';
    }
  };

  return (
    <Box style={{ height: height }}>
      <div
        id={`historicos${ky}`}
        style={{ height: height, marginTop: marginT(dashboard), width: '100%' }}
      />
    </Box>
  );
}
