import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function FFRS
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={22}
            adderFlag={10}
            showLabelInput={true}
            labelBlock={'RS'}
        />
    );
}
export default FFRS