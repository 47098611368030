import React, { useEffect, useState, useContext } from 'react';
//Material ui.
import {
    Box, Button, makeStyles, TextField, InputAdornment, CircularProgress, Chip
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
//componente
import TopNav from '../common/TopNav';
//Componentes
import Container from '../Elements/Container';
import SwitchComponent from './SwitchComponent';
import ModalNodeNotificationSettings from './ModalNodeNotificationSettings';
import ModalTelegram from './ModalTelegram';
import AlertComponent from '../Elements/AlertComponent';
import LoadingComponentBlur from '../common/LoadingComponentBlur';
//Servicios
import {
    getNotificationPreferences, addReferenceNotificationsEmail,
    deleteReferenceNotificationsEmail, editNotificationNodeSettingsObj
} from '../../services/notifications';
import UserContext from '../../context/UserContext';

const useStyles = makeStyles(theme => ({
    containerModules: {
        display: 'flex',
        padding: theme.spacing(0.5, 0, 0.5, 0),
        borderColor: '#a6a0a030',
        borderStyle: 'solid',
        borderWidth: '1px',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    emailContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    email: {
        '& .MuiInputBase-input': {
            fontSize: '12px',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.54)',
        },
    },
    error: {
        marginLeft: theme.spacing(1),
        color: '#af2c47',
        fontSize: '0.75rem',
    },
    filterChip: {
        margin: theme.spacing(0.5),
        paddingLeft: '5px',
    },
    circular: {
        marginLeft: theme.spacing(2),
    },
}))

export default function NotificationSettings() {
    const classes = useStyles()
    const [modulesData, setModulesData] = useState([])
    const [loading, setloading] = useState(true)
    const [loadingAddEmail, setLoadingAddEmail] = useState(false)
    const [generalSettings, setGeneralSettings] = useState(true)
    const [emailReference, seTemailReference] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
    const [settingNotificationNodeState, setSettingNotificationNodeState] = useState({});
    const { plan } = useContext(UserContext);
    //Estado de la alerta dinamica
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [loadingBlurState, setLoadingBlurState] = useState(false)

    useEffect(() => {
        getPreferenceData()
        // eslint-disable-next-line
    }, [refresh])

    //Funcion q trae todos los datos por preferencias de el usuario.
    const getPreferenceData = async () => {
        try {
            //La configuracion base es la configuracion general de las notificaciones la configuracion modulos es para cada una en particular.
            let res = await getNotificationPreferences()
            let modulesDataAux = res?.data.modules
            let base = res?.data.base
            //Si el usuario tiene deshabilitado el envio de emails no va a poder confugurarlo.          
            if (plan?.access?.configuracion_notificaciones_emails === false) {
                base.email_notification = false
                base.email_notificationDisabled = true
            }
            setGeneralSettings(base)
            base.general = true
            setModulesData([base, ...modulesDataAux])
        }
        catch (error) {
        }
        setloading(false)
    }
    //Estado para tomar el email de el input.
    const handleChangeEmail = (e) => {
        seTemailReference(e.target.value)
    }

    //Funcion q agrega un email de referencia.
    const handleAddEmailSubmit = () => {
        try {
            setLoadingAddEmail(true)
            setErrorMessage('')
            let body = {
                email: emailReference,
                preferencia: generalSettings?.id
            }
            addReferenceNotificationsEmail(body)
            setGeneralSettings([
                ...generalSettings,
                body
            ])
        }
        catch (error) {
            setClose(true)
            let msg = 'Ocurrio un error inesperado.'
            if (error?.response?.data?.msg) {
                msg = error?.response?.data?.msg
            }
            setMsg({
                msg: msg,
                type: 'error'
            })
        }
        setRefresh(!refresh)
        seTemailReference('')
        setLoadingAddEmail(false)
    }

    //Funcion q elimina un email de referncia.
    const handleDelete = (id) => {
        setErrorMessage('')
        let filter = generalSettings.emails.filter(e => e.id !== id)
        setGeneralSettings({
            ...generalSettings,
            emails: filter
        })
        deleteReferenceNotificationsEmail(id)
    }

    const handleOpenModalNodeNotificationSettings = (type) => {
        setNotificationModalOpen(true)
        setSettingNotificationNodeState({
            ...settingNotificationNodeState,
            type: type

        })
    }

    const handleCloseModalNodeNotificationSettings = () => {
        setNotificationModalOpen(!isNotificationModalOpen)
    }

    const handleSubmitNotificationSettins = async () => {
        setLoadingBlurState(true)
        try {
            let filterModulesData = modulesData.filter(e => !e.general)
            let objAux = {
                base: generalSettings,
                modules: filterModulesData
            }
            await editNotificationNodeSettingsObj(objAux)
            let msg = "Se han guardado sus cambios con éxito."
            let type = "success"
            setClose(true)
            setMsg({
                msg: msg,
                type: type
            })
        }
        catch (error) {
            let msg = "Ocurrio un error inesperado."
            let type = "error"
            if (error?.response?.data?.msg) {
                msg = error?.response?.data.msg
            }
            setClose(true)
            setMsg({
                msg: msg,
                type: type
            })
        }
        setTimeout(() => {
            setLoadingBlurState(false)
        }, 700);
    }

    return (
        <>
            <TopNav titulo="Configuración" subtitulo='| Notificaciones' />


            {/* Container loading */}
            <LoadingComponentBlur
                loadingState={loadingBlurState}
            />

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container >
                {/* Habilita o deshabilita las notificaciones en un nodo específico y según el tipo de notificación.  */}
                {isNotificationModalOpen &&
                    <ModalNodeNotificationSettings
                        open={isNotificationModalOpen}
                        setOpen={setNotificationModalOpen}
                        settingNotificationNodeState={settingNotificationNodeState}
                        handleCloseModalNodeNotificationSettings={handleCloseModalNodeNotificationSettings}
                    />}
                {
                    loading ?
                        <Box alignItems={'center'} display='flex' justifyContent='center' height={'70vh'}>
                            < CircularProgress />
                        </Box>
                        :
                        <Box m={2} mb={2}>

                            {/* Titulo */}
                            <Box component="h3" color="primary.main" ml={0.5} mt={1} >
                                Centro de configuración de notificaciones
                            </Box>


                            {/* Input email */}
                            < Box color="primary.main" display='flex' alignItems='center' mb={1} style={{ fontWeight: 'bold' }} >
                                {'Agregar destinatario de email'}
                            </Box >

                            <Box className={classes.emailContainer}>
                                <TextField
                                    className={classes.email}
                                    name='email'
                                    value={emailReference}
                                    onChange={handleChangeEmail}
                                    variant='outlined'
                                    size='small'
                                    placeholder={'Ingrese un nuevo email'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant='outlined'
                                    onClick={handleAddEmailSubmit}
                                >
                                    Agregar
                                </Button>
                                {/* Loading de input email */}
                                {loadingAddEmail &&
                                    <Box className={classes.circular}>
                                        <CircularProgress size={23} />
                                    </Box>
                                }
                            </Box>

                            {/* Label de error input email */}
                            <Box mt={1} className={classes.error}>{errorMessage}</Box>
                            {/* Lista de emails habilitados */}
                            <Box minHeight={'30px'} mt={1} mb={2} >
                                {generalSettings?.emails &&
                                    generalSettings?.emails.map((elem, i) => (
                                        <Chip
                                            key={i}
                                            icon={<EmailIcon style={{ fontSize: '15px' }} />}
                                            color='primary'
                                            label={elem.email}
                                            style={elem?.habilitado ? { backgroundColor: '#2586bc' } : { backgroundColor: '#b7c1c7' }}
                                            // onClick={() => handleEnabledEmail(elem.id, elem)}
                                            className={classes.filterChip}
                                            onDelete={() => handleDelete(elem.id)}
                                        />
                                    ))}
                            </Box>

                            {/* Telegram */}
                            <ModalTelegram />

                            {/* MODULOS SWITCH*/}
                            <Box className={classes.containerModules} mt={1} borderRadius={4} bgcolor="background.paper" >
                                {
                                    modulesData.map(((e, inx) => {
                                        return (
                                            < SwitchComponent
                                                key={inx}
                                                element={e}
                                                general={e?.general}
                                                modulesData={modulesData}
                                                setModulesData={setModulesData}
                                                generalSettings={generalSettings}
                                                setGeneralSettings={setGeneralSettings}
                                                handleOpenModalNodeNotificationSettings={handleOpenModalNodeNotificationSettings}

                                            />
                                        )
                                    }))
                                }
                            </Box>

                            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                                <Button
                                    variant="contained"
                                    onClick={handleSubmitNotificationSettins}
                                >
                                    Guardar
                                </Button>
                            </Box>

                        </Box>
                }


            </Container >
        </>
    )
}

