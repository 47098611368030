import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import LabelIcon from '@material-ui/icons/Label';
import { v4 as uuidv4 } from 'uuid';
import {
  Box, Chip, Dialog, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem,
  Select, Snackbar, Step, StepLabel, Stepper, Tooltip
} from '@material-ui/core';
import MultiNode from '../Instant/MultiNode';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import clsx from 'clsx';
import NodesContext from '../../context/NodesContext';
import TagsContext from '../../context/TagsContext';
import UserContext from '../../context/UserContext';
//Servicios
import { getStatusNodes } from '../../services/meters';
//Componentes
import DynamicSelect from '../common/DynamicSelect';
//Hooks
import useDeviceDetect from '../../hooks/useDeviceDetect';
//helpers.
import { blueBar } from '../../helpers/common'

let scrollsSettings = {
  overflow: 'scroll',
  marginBottom: '10px',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(2, 4),
    '& .MuiGrid-root': {
      padding: theme.spacing(1),
    },
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  label: {
    marginLeft: theme.spacing(3),
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(1),
      marginBottom: '5px',
    },
  },
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
  },
  showListBtn: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  devicesBtn: {
    margin: theme.spacing(3, 1),
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  filterLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  scrollableContainer: {
    ...scrollsSettings,
    height: '50vh',
    '@media (max-width: 960px)': {
      height: '15vh',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  selectedItemsContainer: {
    height: '35px',
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },
  steppersFilter: {
    '&.MuiStep-horizontal': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  txtAcumuladoHistorico: {
    fontSize: '16px',
    margin: 0,
  },
  containerNodes: {
    ...scrollsSettings,
    paddingRight: '2px',
    height: '50vh',
    '@media (max-width: 960px)': {
      height: '32vh',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5)
  },
  dialogStyle: {
    '& .MuiDialog-paperScrollPaper': {
      height: '100%'
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 900px)': {
        padding: '2px'
      },
    },
    '& .MuiDialog-paper': {
      '@media (max-width: 600px)': {
        margin: '0px'
      },
    },
    '& .MuiDialog-paperFullWidth': {
      '@media (max-width: 600px)': {
        width: '100%'
      },
    },
  },
  selectGranularity: {
    width: '90%'
  }
}));

export default function Filter({ onSubmit, savedOptions, handleCloseFilter }) {
  const classes = useStyles();
  const blueLine = blueBar()
  const { isMobile } = useDeviceDetect(990);
  const { plan } = useContext(UserContext);
  const { nodes } = useContext(NodesContext);
  const { tags } = useContext(TagsContext);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({});
  const [showDevices, setShowDevices] = useState(false);
  const [selectionList, setSelectionList] = useState([]);
  const [editingId, setEditingId] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Elija el/los dispositivos que desea graficar',
    'Elija la variable a graficar',
    'Elija la granularidad',
    'Elija el mes que desea graficar',
    'Elija si desea mostrar los totales o los valores por fase',
    'Haga click en CONSULTAR',
  ];
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'warning',
  });
  const [filterNotification, setFilterNotification] = useState({
    open: false,
    message: '',
    severity: 'warning',
  });
  const [unsavedOptions, setUnsavedOptions] = useState(savedOptions)
  const [statusNodes, setStatusNodes] = useState([]);

  //Verifica status de el medidor.
  useEffect(() => {
    const getStatusNodesAux = async () => {
      let res = await getStatusNodes()
      let arry = res.data
      setStatusNodes(arry)
    }
    getStatusNodesAux()
  }, [])

  useEffect(() => {
    //Para nodos+tags seleccionados en caso de volver a abrir el panel de filtros
    if (unsavedOptions.dispositivos.length > 0)
      setSelectionList(unsavedOptions.dispositivos);
    // Actualizo el tipo de calendario de acuerdo a la granularidad recibida.
    filterConfigCalendar(unsavedOptions);
    // eslint-disable-next-line 
  }, [unsavedOptions]);

  const enabledItems = plan?.sections["HISTORICOS"].filter(item => item.enabled);

  const objectCatalog = {};

  enabledItems.forEach(item => {
    objectCatalog[item.key] = {
      key: item.key,
      name: item.tag ? item.tag : item.name,
      fields: item.fields,
      periods: item.periods,
      phase: item.phase
    };
  });

  const handleFilterChange = e => {
    if (e.target.name === 'variable') {
      let granularity = enabledItems?.find(item => item.key === e.target.value)
      let period = granularity.periods[0]?.key
      //Si elije la otra variable y la granularidad q tenia coincide con una opcion valida la sigo mostrando.
      let findSameGranularity = granularity?.periods.find(item => item.key === unsavedOptions?.granularity)
      setUnsavedOptions({
        ...unsavedOptions,
        variable: e.target.value,
        variableFields: objectCatalog[e.target.value]?.fields,
        variableName: objectCatalog[e.target.value]?.name,
        showPhase: objectCatalog[e.target.value]?.phase,
        granularity: findSameGranularity ? findSameGranularity.key : period
      });
      setActiveStep(3)
    }
    if (e.target.name === 'granularity') {
      setActiveStep(3)
      setUnsavedOptions({
        ...unsavedOptions,
        granularity: e.target.value
      });
      filterConfigCalendar({
        ...unsavedOptions,
        granularity: e.target.value
      });
    }

  };

  const [filterCalendarConfig, setFilterCalendarConfig] = useState({
    views: ['month', 'year'],
    disabled: false,
    format: 'MM/yyyy',
  });

  const filterConfigCalendar = filter => {
    const config = {};
    switch (unsavedOptions.granularity) {
      case 'DAILY':
        config.views = ['month', 'year'];
        config.disabled = false;
        config.format = 'MM/yyyy';
        //setSelectedDate(new Date());
        break;
      case 'MONTHLY':
        config.views = ['year'];
        config.disabled = false;
        config.format = 'yyyy';
        //setSelectedDate(new Date());
        break;
      case 'YEARLY':
        config.disabled = true;
        config.format = 'yy';
        //Como voy a mostrar todos los anuales muestro la fecha de hoy
        //setSelectedDate(new Date());
        break;
      default:
        return;
    }

    setFilterCalendarConfig(config);
  };

  const nodeList = () => {
    return nodes?.map(node => (
      <MultiNode
        node={node}
        handleSelectedNode={handleSelectedNode}
        selectedNodes={selectedNodes}
        key={node.id}
        statusNodes={statusNodes}
      />
    ));
  };

  const labelsList = () => {
    return tags?.map(tag => (
      <ListItem
        key={tag.id}
        button
        onClick={() => handleSelectedLabel(tag.nombre, tag.id)}
        selected={selectedLabel ? selectedLabel.id === tag.id : false}
        className={classes.selected}
      >
        <ListItemText primary={tag.nombre} />
      </ListItem>
    ));
  };

  const handleSelectedNode = node => {
    setActiveStep(5);
    setSelectedNodes(prevSelectedNodes => {
      const nodeIndex = prevSelectedNodes.findIndex(
        selectedNode => selectedNode.id === node.id
      );
      // Si el nodo está en la lista lo elimino
      if (nodeIndex >= 0) {
        return prevSelectedNodes.filter(
          selectedNode => selectedNode.id !== node.id
        );
      } else {
        // Si el nodo no está en la lista.

        // Si estoy en modo edición y ya tenia un nodo seleccionado, como quiso elegir otro se lo impido.
        if (editingId && prevSelectedNodes.length > 0) {
          setNotification({
            isOpen: true,
            msg: 'En modo edición no es posible la selección múltiple.',
            severity: 'warning',
          });
          return prevSelectedNodes;
        }
        // Si tengo una etiqueta seleccionada
        if (selectedNodes.length > 0 && selectedLabel.id) {
          setNotification({
            isOpen: true,
            msg: 'Sólo es posible una selección múltiple si no tiene etiquetas seleccionadas.',
            severity: 'warning',
          });
          return prevSelectedNodes;
        }
        const newSelectedNodes = [...prevSelectedNodes, node];
        return newSelectedNodes;
      }
    });
  };


  const handleSelectedLabel = (label, id) => {
    if (selectedNodes.length > 1) {
      return setNotification({
        isOpen: true,
        msg: 'Sólo es posible elegir etiquetas con un único dispositivo seleccionado.',
        severity: 'warning',
      });
    }
    // Si la etiqueta elegida es la misma que la actualmente seleccionado la deselecciono
    if (label === selectedLabel.nombre && id === selectedLabel.id)
      setSelectedLabel({});
    else setSelectedLabel({ nombre: label, id: id });
  };

  const handleCloseDevicesDialog = () => {
    setSelectedNodes([]);
    setSelectedLabel({});
    setShowDevices(false);
  };

  const handleDeleteNodeSelection = id => {
    setSelectedNodes(prevState => {
      const newNodes = prevState.filter(node => node.id !== id);
      return newNodes;
    });
  };

  const handleDeleteLabelSelection = () => {
    setSelectedLabel({});
  };

  // Submit en Dialog de dispositivos
  const handleSubmitSelectionItem = () => {
    setShowDevices(false);
    let newSelection = [];
    // Voy a recibir dos tipos de situaciones.
    // a) nodo - nodo + etiqueta
    // Este es el caso que se da cuando estoy editando (editingId!==0).
    if (selectedNodes.length === 1) {
      newSelection = editingId
        ? selectionList.map(item => {
          if (item.id === editingId) {
            return { ...item, node: selectedNodes[0], label: selectedLabel };
          }
          return item;
        })
        : [
          ...selectionList,
          { id: uuidv4(), node: selectedNodes[0], label: selectedLabel },
        ];
    }

    if (selectedNodes.length > 1) {
      // b) array de nodos
      // Solo tengo que agregar los elementos si no forman parte de una selección anterior.
      // Puedo usar como id el id del nodo.
      newSelection = selectedNodes.map(node => ({
        id: uuidv4(),
        node,
        label: {},
      }));
    }

    // Quiero cubrir estos casos:
    // Si estoy editando un nodo (sin tag) y elijo nodo+tag o viceversa.
    // Si estoy editando un nodo y elijo algo existente.
    // Si estoy editando quiero eliminar el nodo viejo porque se supone que en newSelection ya lo tengo (sino tendria dos con el mismo id)
    let savedValues = unsavedOptions.dispositivos;
    if (editingId) {
      // Quito el valor original, porque tengo uno nuevo
      savedValues = savedValues.filter(item => item.id !== editingId);
    }

    // Elimino los repetidos
    const filteredSelection = newSelection.filter(newItem => {
      const isDuplicate = savedValues.some(oldItem => {
        return (
          oldItem.node.nombre === newItem.node.nombre &&
          oldItem.label.nombre === newItem.label.nombre
        );
      });
      return !isDuplicate;
    });

    const mergedArray = [...savedValues, ...filteredSelection];

    setSelectionList(mergedArray);
    setActiveStep(1);
    // Como modifiqué la selección reseteo todo
    // setUnsavedOptions({ ...unsavedOptions, dispositivos: mergedArray, });
    setUnsavedOptions({ ...unsavedOptions, dispositivos: mergedArray, variable: '', variableFields: '', variableName: '', showPhase: false, granularity: '' });
    setSelectedNodes([]);
    setSelectedLabel({});
  };

  const handleDeleteSelectionItem = (id, type) => {
    const filteredList = selectionList.filter(item => {
      return item.id !== id;
    });
    setSelectionList(filteredList);
    //Actualizo filter para que el submit en Historical tenga el valor actualizado si borré algo de la lista
    // Como borré algo de la lista reseteo todo
    // setUnsavedOptions({ ...unsavedOptions, dispositivos: filteredList });
    setUnsavedOptions({ ...unsavedOptions, dispositivos: filteredList, variable: '', variableFields: '', variableName: '', showPhase: false, granularity: '' });
  };

  const handleCancelFilterDialog = () => {
    handleCloseFilter();
  };

  // Click en óvalo azul dentro del panel de filtrar
  const handleClickedSelectionItem = (item, type) => {
    // Como estoy editando cargo editingId con el id del item clickeado.
    setEditingId(item.id);
    // Mostrar lista de dispositivos
    // showDevices se activa siempre junto a listType
    setShowDevices(true);
    // Establezco el nodo seleccionado
    setSelectedNodes([item.node]);
    // Establezco la etiqueta seleccionada
    setSelectedLabel(item.label);
  };

  const handlePreSubmit = e => {
    //El usuario presionó CONSULTAR.
    e.preventDefault();
    onSubmit(unsavedOptions);
  };

  const hasSomeVirtualNodes = () => {
    return unsavedOptions?.dispositivos?.some(item => item?.node?.virtual);
  };

  return (
    <>
      <Stepper
        className={classes.steppersFilter}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{isMobile ? '' : label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {blueLine}

      <Snackbar
        open={filterNotification.isOpen}
        autoHideDuration={2000}
        onClose={() =>
          setFilterNotification({ ...filterNotification, isOpen: false })
        }
      >
        <Alert
          severity={filterNotification.severity}
          onClose={() => setFilterNotification(false)}
        >
          {filterNotification.msg}
        </Alert>
      </Snackbar>
      <form className={classes.root} onSubmit={handlePreSubmit}>
        <Grid container style={{ padding: 0 }}>
          {/* Dispositivos */}
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              minHeight={40}
            >
              <Tooltip
                title="Elija el/los dispositivos que desea añadir al gráfico haciendo click en el botón +"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: activeStep === 0 && unsavedOptions?.dispositivos.length === 0,
                    },
                    {
                      [classes.tick]: activeStep > 0 || unsavedOptions?.dispositivos?.length > 0,
                    }
                  )}
                >
                  1
                </Box>
              </Tooltip>
              <Box className={classes.devicesLabel}>Agregar dispositivo</Box>
              <IconButton
                aria-label="agregar dispositivos"
                className={classes.addBtn}
                onClick={() => {
                  setShowDevices(true);
                  // Como no estoy editando pongo editingId en 0
                  setEditingId(0);
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>

              {selectionList.length > 0 &&
                selectionList.map(item => (
                  <Chip
                    key={item.id}
                    icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                    label={`${item.node.nombre}  ${item.label.nombre ? `+ ${item.label.nombre}` : ''
                      }`}
                    clickable
                    color="primary"
                    onDelete={() => handleDeleteSelectionItem(item.id)}
                    className={classes.filterChip}
                    onClick={() => handleClickedSelectionItem(item)}
                  />
                ))}
            </Box>
          </Grid>
          {/* Variable */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              minHeight={40}
              alignItems='flex-end'
            >
              <Tooltip
                title="Elija la variable a graficar"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: activeStep === 1 && !unsavedOptions.variable,
                    },
                    {
                      [classes.tick]: activeStep > 1 || unsavedOptions?.variable,
                    }
                  )}
                >
                  {/* {activeStep > 1 ? <DoneIcon style={{ padding: '2px' }} /> : 2} */}
                  2
                </Box>
              </Tooltip>

              <  Box mt={1} width={'100%'}>
                {/* disabled={(item.node === "FISICO" && hasSomeVirtualNodes() === true)} */}
                {/* Selector q agrupa las variables dinamico */}
                <DynamicSelect
                  disabledSelect={unsavedOptions.dispositivos.length === 0}
                  disabledVarianteHistoric={(hasSomeVirtualNodes() === true)}
                  handleChangeProp={handleFilterChange}
                  valueSelect={unsavedOptions.variable}
                  keyName={'variable'}
                  InputLabelProp={'Variable principal a graficar'}
                  section={'HISTORICOS'}
                />
              </Box  >

            </Box>
            {
              unsavedOptions.dispositivos.length === 0 &&
              <Box className={classes.help}>Debe elegir al menos un dispositivo antes de elegir la variable.</Box>
            }
          </Grid>

          {/* Granularidad */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center">
              <Tooltip
                title="Elija la granularidad deseada"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: activeStep === 2 && !unsavedOptions.granularity,
                    },
                    {
                      [classes.tick]: activeStep > 2 || unsavedOptions?.granularity,
                    }
                  )}
                >
                  3
                </Box>
              </Tooltip>
              <FormControl fullWidth>
                <InputLabel id="granularityLabel">Granularidad</InputLabel>
                <Select
                  className={classes.selectGranularity}
                  labelId="granularityLabel"
                  name="granularity"
                  value={unsavedOptions.granularity}
                  onChange={handleFilterChange}
                  disabled={unsavedOptions.variable === ''}
                >
                  {objectCatalog[unsavedOptions.variable]?.periods.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {
              unsavedOptions.variable === '' &&
              <Box className={classes.help}>Debe elegir una variable antes de elegir la granularidad.</Box>
            }
          </Grid>

          {/* Fecha */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center">
              <Tooltip
                title="Elija los días que desea graficar"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(classes.hint, classes.leftSpacing, {
                    [classes.tick]: activeStep > 2 || unsavedOptions?.selectedDate,
                  })}
                >
                  4
                </Box>
              </Tooltip>
              <Box className={classes.filterLabel}>Fecha</Box>
            </Box>

            {unsavedOptions.granularity !== 'YEARLY' ? (
              <Box display="flex" justifyContent="center">
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={filterCalendarConfig.disabled}
                    autoOk
                    views={filterCalendarConfig.views}
                    disableToolbar
                    variant="inline"
                    format={filterCalendarConfig.format}
                    margin="dense"
                    // id='date-picker-inline'
                    // label='Date picker inline'
                    value={unsavedOptions.selectedDate}
                    onChange={date =>
                      setUnsavedOptions({ ...unsavedOptions, selectedDate: date })
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'Establecer fecha',
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <p className={classes.txtAcumuladoHistorico}>
                  Acumulado Histórico
                </p>
              </Box>
            )}
          </Grid>
        </Grid>

        {unsavedOptions.dispositivos.length === 0 && (
          <Box className={classes.help}>
            Debe seleccionar al menos un dispositivo.
          </Box>
        )}
        {unsavedOptions.variable === "" && (
          <Box className={classes.help}>
            Debe seleccionar una variable.
          </Box>
        )}
        {unsavedOptions.granularity === "" && (
          <Box className={classes.help}>
            Debe seleccionar la granularidad.
          </Box>
        )}

        {blueLine}

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.btn}
            disabled={selectionList.length === 0 || unsavedOptions.variable === "" || unsavedOptions.granularity === ""}
          >
            Consultar
          </Button>

          <Button
            variant="contained"
            onClick={handleCancelFilterDialog}
            className={classes.btn}
          >
            Cancelar
          </Button>
        </Box>
      </form>

      <Dialog
        className={classes.dialogStyle}
        open={showDevices}
        onClose={handleCloseDevicesDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <Snackbar
          open={notification.isOpen}
          autoHideDuration={2000}
          onClose={() => setNotification({ ...notification, isOpen: false })}
        >
          <Alert
            severity={notification.severity}
            onClose={() => setNotification(false)}
          >
            {notification.msg}
          </Alert>
        </Snackbar>
        <Box padding="8px">
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Selección de Dispositivos
          </DialogTitle>

          <Box mb={4} className={classes.dialogSubtitle}>
            Seleccionar uno o más elementos de la organización o un elemento y
            una etiqueta.
          </Box>

          {blueLine}

          <Box display="flex" className={classes.selectedItemsContainer}>
            {selectedNodes.map(node => (
              <Chip
                key={node.id}
                className={classes.wordCut}
                icon={<AccountTreeIcon />}
                label={node.nombre}
                clickable
                color="primary"
                onDelete={() => handleDeleteNodeSelection(node.id)}
              />
            ))}

            {selectedLabel.nombre ? (
              <Chip
                icon={<LabelIcon />}
                label={selectedLabel.nombre}
                clickable
                color="secondary"
                onDelete={handleDeleteLabelSelection}
              />
            ) : null}
          </Box>

          <Grid container display="block">
            <Grid item xs={12} md={6}>
              <Box
                component="label"
                display="block"
                className={clsx(classes.label, classes.marginNone)}
              >
                Organización
              </Box>

              <Box className={classes.containerNodes}>
                {nodes?.length === 0 && (
                  <h2
                    style={{
                      textAlign: 'center',
                      fontSize: '19px',
                      color: '#2586bc',
                      paddingTop: '20px',
                    }}
                  >
                    Usted no posee ninguna localización configurada en su
                    cuenta.<br></br>
                    Por favor, configure al menos una localización para poder
                    continuar.
                    <NavLink
                      className={classes.navLink}
                      to={`/configuracion-organizacion`}
                    >
                      [link]
                    </NavLink>
                  </h2>
                )}
                <List component="div" disablePadding dense>
                  {nodeList()}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="label" display="block" className={classes.label}>
                Etiquetas
              </Box>
              <List component="div" disablePadding dense>
                <List
                  component="div"
                  disablePadding
                  dense
                  className={classes.scrollableContainer}
                >
                  {labelsList()}
                </List>
              </List>
            </Grid>
          </Grid>

          {blueLine}

          <Box display="flex" justifyContent="center">
            <Button
              className={classes.devicesBtn}
              variant="contained"
              color="primary"
              onClick={handleSubmitSelectionItem}
              disabled={selectedNodes.length === 0}
            >
              {!editingId ? 'Agregar' : 'Editar'}
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseDevicesDialog}
              className={classes.devicesBtn}
            >
              Cancelar
            </Button>
          </Box>

        </Box>
      </Dialog>
    </>
  );
}
