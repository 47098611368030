//Componentes
import ContainerChartHistorical from '../Dashboards/ComponentsDashboards/ContainerChartHistorical'
import ContainerChartInstant from "../Dashboards/ComponentsDashboards/ContainerChartInstant";
import ContainerCards from '../Dashboards/ComponentsDashboards/ContainerCards';
import ContainerDynamicGauge from '../Dashboards/ComponentsDashboards/ContainerDynamicGauge';
import ContainerImgDashboardPersonalizado from '../Dashboards/ComponentsDashboards/ContainerImgDashboardPersonalizado';
import ContainerEnergyFlowChart from '../Dashboards/ComponentsDashboards/ContainerEnergyFlowChart';
import ContainerEventsCard from '../Dashboards/ComponentsDashboards/ContainerEventsCard';
import ContainerDiferenceBar from '../Dashboards/ComponentsDashboards/ContainerDiferenceBar';
//Opciones de la variedad de componentes para elegir
export const OPTIONS = {
    barHistoricGraph: "Históricos acumulados (gráfico de barras)",
    lineInstantGraph: "Instantáneos (gráfico de línea)",
    numberIndicator: "Indicador numerico",
    gauge: "Indicador gauge",
    imageContainer: "Contenedor de imagen",
    listEvents: "Lista de eventos",
    flowEnergy: "Flujo de energia",
    ShowDiferenceBar: 'Comparador',
}

//Funcion q verifica si el usuario esta en la ruta '/dashboard-publico.'
export const chekUrlPublic = () => {
    //Validar estar en ruta 'dashboard-public'.
    const ruta = window.location.pathname;
    const disabledDrawer = ruta.split('/')[1] === 'dashboard-publico'
    return disabledDrawer
}

// Ejemplo (config_key) "DASHBOARD_HISTORY-TAN_(FI)_TOTAL-VALIDATE_HISTORY-ESTE_ANO-DAILY"
export const helperComponent = (VAR) => {
    let nameVarLocal = 'UI_ROLES'
    if (chekUrlPublic()) {
        nameVarLocal = 'PUBLIC'
    }
    let VARS_CATALOGO = JSON.parse(localStorage.getItem(nameVarLocal)).sections[VAR]
    let filterKey = ""
    let array = VARS_CATALOGO?.map((elem) => {
        let res = {
            ...elem,
            label: elem.tag ? elem.tag : elem.name,
            value: `${elem.key}-${elem?.fields}-${elem?.phase}`,
            config_key: `${VAR}-${elem.key}-${filterKey}`,
        };
        return res
    });
    return array;
};

//Funciones para transformar en formato json la respuesta de content.
const isValidJSON = (jsonString) => {
    try {
        JSON.parse(jsonString);
        return true;
    }
    catch (error) {
        return false;
    }
}

//Función q transforma el objeto (content) formato string a json.
const readJson = (json_string) => {
    if (isValidJSON(json_string)) {
        const json_object = JSON.parse(json_string);
        return json_object
    }
    else {
        return null
    }
}

//Función q transforma el objeto (content) formato string a json componente image.
const parseJsonString = (stringData) => {
    if (typeof stringData === 'string' && stringData.length >= 2) {
        // Reemplazar comillas simples por comillas dobles
        const jsonString = stringData.replace(/'/g, '"');
        // Convertir la cadena JSON en un objeto JavaScript
        const jsonData = JSON.parse(jsonString);
        return jsonData
    }
    else {
        return {
            scale: 1,
            positionX: 1,
            positiony: 1
        }
    }
}

//Función encargada de renderizar el componente y su configuración según el "nameComponent".
export const listComponent = (
    id, nameComponent, index, nameDevice, variable, config_key,
    content, color, min, max, positions, refresh, nodes, img, edit, setEdit,
    editFunctionImg, positionsAux, image_zoom, setStateData, stateData, setStateDataComponentsImageZoom
) => {
    //Históricos acumulados (gráfico de barras).
    if (nameComponent === OPTIONS.barHistoricGraph)
        return (
            < ContainerChartHistorical
                content={readJson(content)}
                config_key={config_key}
                height={'90%'}
                id={id}
                title={nameDevice}
                ky={index}
                nodes={nodes}
                refresh={refresh}
            />
        )
    //"Instantáneos (gráfico de línea)".
    if (nameComponent === OPTIONS.lineInstantGraph)
        return (
            < ContainerChartInstant
                content={readJson(content)}
                config_key={config_key}
                height={'82%'}
                id={id}
                title={nameDevice}
                ky={index}
                nodes={nodes}
                refresh={refresh}
            />
        )
    //"Indicador numerico".
    if (nameComponent === OPTIONS.numberIndicator)
        return (
            < ContainerCards
                content={readJson(content)}
                config_key={config_key}
                id={id}
                title={nameDevice}
                variable={variable}
                size={true}
                refresh={refresh}
            />
        )
    //"Indicador gauge".
    if (nameComponent === OPTIONS.gauge)
        return (
            < ContainerDynamicGauge
                content={readJson(content)}
                config_key={config_key}
                id={id}
                title={nameDevice}
                index={index}
                variable={variable}
                color={color}
                min={min}
                max={max}
                positions={positions}
                refresh={refresh}
            />
        )
    //"Contenedor de imagen"
    if (nameComponent === OPTIONS.imageContainer)
        return (
            <ContainerImgDashboardPersonalizado
                img={img}
                image_zoom={parseJsonString(image_zoom)}
                edit={edit}
                setEdit={setEdit}
                editFunctionImg={editFunctionImg}
                positionsAux={positionsAux}
                stateData={stateData}
                setStateDataComponentsImageZoom={setStateDataComponentsImageZoom}
                index={index}
            />
        )
    if (nameComponent === OPTIONS.listEvents)
        return (
            <ContainerEventsCard
                title={'Titulo de prueba'}
                subtitle={'| Subtitulo'}
                positions={positions} />
        )
    if (nameComponent === OPTIONS.flowEnergy)
        return (
            <ContainerEnergyFlowChart
                content={readJson(content)}
                config_key={config_key}
                id={id}
                title={nameDevice}
                height={'90%'}
                positions={positions} />
        )
    if (nameComponent === OPTIONS.ShowDiferenceBar)
        return (
            <ContainerDiferenceBar
                config_key={config_key}
                title={"Generación"}
                id={id}
                color={color}
            />
        )
}
