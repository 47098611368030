import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  makeStyles,
} from '@material-ui/core';

import TopNav from '../../common/TopNav';
import Node from '../Node';
import { useContext, useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import NodesContext from '../../../context/NodesContext';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import {
  getExtraInfo,
  getNodes,
  getNodesTree,
} from '../../../services/hierarchy';
import {
  saveNodesInLocalStorage,
  saveNodesListInLocalStorage,
} from '../../../services/auth';
import { getControlSetting, getStatusNodes } from '../../../services/meters';
import AlarmsControl from '../../ControlPanel/AlarmsControl';
import Container from '../../Elements/Container';

const scrollsSettings = {
  overflow: 'scroll',
  height: '88vh',
  overflowX: 'hidden',
};

const webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  width: '9px',
};

const webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    display: 'flex',
  },
  leftContainer: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    //son los estilos de las barras scroll en una variable.
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  rightContainer: {
    // borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    //son los estilos de las barras scroll en una variable
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },

  buttonBack: {
    marginTop: '10px',
    position: 'relative',
    bottom: '16px',
    fontSize: '20px',
    backgroundColor: '#f3f3f3',
    width: '30px',
    borderRadius: '1em',
    textAlign: 'center',
    fontWeight: 'bold',
  },

  alerts: {
    height: '60px',
    fontSize: 'large',
    zIndex: '2',
    margin: theme.spacing(1, 0, 0, 0),
  },
}));

export default function AlarmsOnDevices() {
  const classes = useStyles();
  const { nodes, setNodes } = useContext(NodesContext);
  const [status, setStatus] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});
  const [treeChanges, setTreeChanges] = useState(false);
  const { isMobile } = useDeviceDetect(959);
  const deviceDetectCustomResolution = useDeviceDetect(1600);
  const [showRightBlock, setShowRightBlock] = useState(true);
  const [showLeftBlock, setShowLeftBlock] = useState(true);
  const [loading, setLoading] = useState(false);
  const [stateData, setStateData] = useState(null);
  const [dataAux, setDataAux] = useState({});
  const [alertState, setAlertState] = useState({});
  const [loadingControl, setLoadingControl] = useState(false);

  useEffect(() => {
    const fetchNodesTreeData = async () => {
      try {
        setLoading(true);
        const res = await getNodesTree();
        saveNodesInLocalStorage(res.data);
        const resStatus = await getStatusNodes();
        setStatus(resStatus.data);
        setNodes(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchNodesTreeData();
  }, [treeChanges, setNodes]);

  useEffect(() => {
    if (isMobile) {
      setShowRightBlock(false);
    } else {
      setShowLeftBlock(true);
      setShowRightBlock(true);
    }
  }, [isMobile]);

  useEffect(() => {
    const fetchNodes = async () => {
      try {
        const res = await getNodes();
        saveNodesListInLocalStorage(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchNodes();
  }, [treeChanges]);

  const handleSelectedNode = async node => {
    try {
      if (isMobile) {
        setShowLeftBlock(false);
        setShowRightBlock(true);
      }
      setAlertState({
        close: false,
      });
      setStateData(null);
      setLoadingControl(true);
      setSelectedNode(node);
      await getStateData(node);
      setLoadingControl(false);
    } catch (error) {
      let msg = 'Ocurrio un error inesperado';
      let type = 'error';
      if (error?.response?.data.MSJ) {
        msg = error.response.data.MSJ;
      }
      setAlertState({
        close: true,
        msg: msg,
        type: type,
      });
      setLoadingControl(false);
    }
  };

  const getStateData = async node => {
    let extraInfo = await getExtraInfo(node.id);
    const dataAux = {
      plan: extraInfo.data?.product,
      id: extraInfo.data?.medidor_id,
    };
    setDataAux(dataAux);
    let key = 'UMBRALES_GET&key=TIMER_GET&key=RELAY_STATUS';
    let res = await getControlSetting(key, dataAux?.id);
    let UMBRALES_GET = res?.data.find(objeto => objeto.key === 'UMBRALES_GET');
    let TIMER_GET = res?.data.find(objeto => objeto.key === 'TIMER_GET');
    let RELAY_STATUS = res?.data.find(objeto => objeto.key === 'RELAY_STATUS');
    setStateData({
      UMBRALES_GET,
      TIMER_GET,
      RELAY_STATUS,
    });
  };

  const handleBackClick = () => {
    setShowLeftBlock(true);
    setShowRightBlock(false);
  };

  const paddingForDesktopView = () => {
    let paddingForDesktop = {
      paddingRight: '12em',
      paddingLeft: '12em',
    };
    return deviceDetectCustomResolution.isMobile ? null : paddingForDesktop;
  };

  return (
    <>
      <TopNav titulo={'Dispositivos'}>
        <Box display="flex" justifyContent="flex-end"></Box>
      </TopNav>

      <Container>
        {/* ALERTAS */}
        {alertState.close && (
          <Alert
            className={classes.alerts}
            severity={alertState.type}
            action={
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setAlertState({
                    ...alertState,
                    close: false,
                  });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertState.msg}
          </Alert>
        )}
        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid className={classes.root} container>
            {showLeftBlock && (
              <Grid item xs={12} md={6} className={classes.leftContainer}>
                <List>
                  <Box>
                    {nodes.map(node => (
                      <Node
                        handleSelectedNode={handleSelectedNode}
                        node={node}
                        selectedNode={selectedNode}
                        key={node.id}
                        setTreeChanges={setTreeChanges}
                        statusNodes={status}
                        onlyPhysicalDevices={true}
                        disabled={loadingControl}
                      />
                    ))}
                  </Box>
                </List>
              </Grid>
            )}
            {showRightBlock && (
              <Grid
                style={paddingForDesktopView()}
                item
                xs={12}
                md={6}
                className={classes.rightContainer}
              >
                {isMobile && (
                  <Box className={classes.buttonBack} onClick={handleBackClick}>
                    {'<'}
                  </Box>
                )}
                {!loadingControl && stateData && (
                  <Box className={classes.box}>
                    <AlarmsControl
                      stateData={stateData}
                      options={dataAux}
                      setAlertState={setAlertState}
                      fromAlertsOnDevices={true}
                    />
                  </Box>
                )}
                {loadingControl && (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </>
  );
}

