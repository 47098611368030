
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
//Icons
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LabelIcon from '@material-ui/icons/Label';
//Material ui.
import {
    Button, Box, Chip, Dialog, DialogTitle, List, ListItem, ListItemText, Snackbar, Grid, makeStyles
} from '@material-ui/core';
import MultiNode from '../Instant/MultiNode';
//Componentes
import { Alert } from '@mui/material';
import NodesContext from '../../context/NodesContext';
import TagsContext from '../../context/TagsContext';
import { getStatusNodes } from '../../services/meters';
//helpers.
import { blueBar } from '../../helpers/common'

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    selected: {
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            martinRight: '20px',
        },
    },
    devicesBtn: {
        margin: theme.spacing(1, 1),
    },
    dialogTitle: {
        '@media (max-width: 700px)': {
            textAlign: 'center',
        },
    },
    dialogSubtitle: {
        marginLeft: theme.spacing(3),
        color: theme.palette.primary.main,
        '@media (max-width: 700px)': {
            display: 'none',
        },
    },
    btn: {
        margin: theme.spacing(3, 1),
        '@media (max-width: 700px)': {
            fontSize: '13px',
            padding: '1%',
        },
    },
    selectedItemsContainer: {
        height: '35px',
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(3),
    },
    scrollableContainer: {
        height: 'max-content',
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        minWidth: '25px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    active: {
        backgroundColor: theme.palette.primary.main,
    },
    containerTags: {
        ...scrollsSettings,
        height: '50vh',
        '@media (max-width: 960px)': {
            height: '15vh',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiList-root.MuiList-dense': {
            // marginRight: "10px",
        },
        '@media (max-width: 1100px)': {
            width: '100%',
        },
    },
    containerNodes: {
        paddingRight: '5px',
        ...scrollsSettings,
        height: '50vh',
        '@media (max-width: 960px)': {
            height: '32vh',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiList-root.MuiList-dense': {
            // marginRight: "10px",
        },
        '@media (max-width: 1100px)': {
            width: '100%',
        },
    },
    wordCut: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    dialogStyle: {
        '& .MuiDialog-paperScrollPaper': {
            height: '100%'
        },
        '& .MuiDialogContent-root': {
            '@media (max-width: 900px)': {
                padding: '2px'
            },
        },
        '& .MuiDialog-paper': {
            '@media (max-width: 600px)': {
                margin: '0px'
            },
        },
        '& .MuiDialog-paperFullWidth': {
            '@media (max-width: 600px)': {
                width: '100%'
            },
        },
    }
}));


export default function SelectNodeLabel(    {
        showDevices, handleOpenCloseSelectNodeTag, setNodeLabelSelected, handleSubmit, multi = false
    }
) {
    const classes = useStyles();
    const { nodes } = useContext(NodesContext);
    const { tags } = useContext(TagsContext);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState({});
    const [editingId, setEditingId] = useState(0);
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'warning',
    });
    const [statusNodes, setStatusNodes] = useState([]);

    //Verifica status de el medidor.
    useEffect(() => {
        const getStatusNodesAux = async () => {
            let res = await getStatusNodes()
            let arry = res.data
            setStatusNodes(arry)
        }
        getStatusNodesAux()
    }, [])

    // Guardo estado de seleccion nodo etiqueta.
    useEffect(() => {
        // setNodeLabelSelected(
        //     {
        //         selectedNodes: selectedNodes[0],
        //         selectedLabel
        //     }
        // )      
    }, [selectedNodes, selectedLabel])

    const handleCloseDevicesDialog = () => {
        setSelectedNodes([]);
        setSelectedLabel({});
        handleOpenCloseSelectNodeTag()
    };

    const handleDeleteNodeSelection = id => {
        setSelectedNodes(prevState => {
            const newNodes = prevState.filter(node => node.id !== id);
            return newNodes;
        });
    };

    const handleDeleteLabelSelection = () => {
        setSelectedLabel({});
    };

    // Submit en Dialog de dispositivos
    const handleSubmitSelectionItem = () => {        
        setNodeLabelSelected(prevState => ({
            ...prevState,
            selectedNodes: selectedNodes,
            selectedLabel,
        }));
        
        handleOpenCloseSelectNodeTag()
        // setSelectedNodes([]);
        // setSelectedLabel({});
    };

    const nodeList = () => {
        return nodes?.map(node => (
            <MultiNode
                node={node}
                handleSelectedNode={handleSelectedNodes}
                selectedNodes={selectedNodes}
                key={node.id}
                statusNodes={statusNodes}
            />
        ));
    };

    const labelsList = () => {
        return tags?.map(tag => (
            <ListItem
                key={tag.id}
                button
                onClick={() => handleSelectedLabel(tag.nombre, tag.id)}
                selected={selectedLabel ? selectedLabel.id === tag.id : false}
                className={classes.selected}
            >
                <ListItemText primary={tag.nombre} />
            </ListItem>
        ));
    };

    const handleSelectedUniqueNode = node => {
        setSelectedNodes([node])
    };

    const handleSelectedMultiplesNodes = node => {
        setSelectedNodes(prevSelectedNodes => {
          const nodeIndex = prevSelectedNodes.findIndex(
            selectedNode => selectedNode.id === node.id
          );
          // Si el nodo está en la lista lo elimino
          if (nodeIndex >= 0) {
            return prevSelectedNodes.filter(
              selectedNode => selectedNode.id !== node.id
            );
          }
          else {
            // Si el nodo no está en la lista.
            // Si estoy en modo edición y ya tenia un nodo seleccionado, como quiso elegir otro se lo impido.
            if (editingId && prevSelectedNodes.length > 0) {
              setNotification({
                isOpen: true,
                msg: 'En modo edición no es posible la selección múltiple.',
                severity: 'warning',
              });
              return prevSelectedNodes;
            }
            // Si tengo una etiqueta seleccionada
            if (selectedNodes.length > 0 && selectedLabel.id) {
              setNotification({
                isOpen: true,
                msg: 'Sólo es posible una selección múltiple si no tiene etiquetas seleccionadas.',
                severity: 'warning',
              });
              return prevSelectedNodes;
            }
            const newSelectedNodes = [...prevSelectedNodes, node];
            return newSelectedNodes;
          }
        });
      };

    const handleSelectedNodes = nodes => {
        if(multi){
            handleSelectedMultiplesNodes(nodes)
        }else{
            handleSelectedUniqueNode(nodes)
        }
    }

    const handleSelectedLabel = (label, id) => {
        if (selectedNodes.length > 1) {
            return setNotification({
                isOpen: true,
                msg: 'Sólo es posible elegir etiquetas con un único dispositivo seleccionado.',
                severity: 'warning',
            });
        }
        // Si la etiqueta elegida es la misma que la actualmente seleccionado la deselecciono
        if (label === selectedLabel.nombre && id === selectedLabel.id) {
            setSelectedLabel({});
        }
        else {
            setSelectedLabel({ nombre: label, id: id });
        }
    };

    return (

        <Dialog
            className={classes.dialogStyle}
            open={showDevices}
            onClose={handleOpenCloseSelectNodeTag}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            fullWidth
            disableScrollLock
        >
            <Snackbar
                open={notification.isOpen}
                autoHideDuration={2000}
                onClose={() => setNotification({ ...notification, isOpen: false })}
            >
                <Alert
                    severity={notification.severity}
                    onClose={() => setNotification(false)}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>

            <Box padding="5px">
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                    Selección de Dispositivos para gráfico
                </DialogTitle>
                <Box className={classes.dialogSubtitle}>
                    Seleccionar uno o más elementos de la organización o un elemento y
                    una etiqueta.
                </Box>

                <Box display="flex" className={classes.selectedItemsContainer}>
                    {selectedNodes.map(node => (
                        <Chip
                            key={node.id}
                            className={classes.wordCut}
                            icon={<AccountTreeIcon />}
                            label={node.nombre}
                            clickable
                            color="primary"
                            onDelete={() => handleDeleteNodeSelection(node.id)}
                        />
                    ))}

                    {selectedLabel.nombre ? (
                        <Chip
                            icon={<LabelIcon />}
                            label={selectedLabel.nombre}
                            clickable
                            color="secondary"
                            onDelete={handleDeleteLabelSelection}
                        />
                    ) : null}
                </Box>

                <Grid container display="block">
                    <Grid item xs={12} md={6}>

                        {blueBar()}

                        <Box component="label" display="block" className={classes.label}>
                            Organización
                        </Box>

                        <Box className={classes.containerNodes}>
                            {nodes?.length === 0 && (
                                <h2
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '19px',
                                        color: '#2586bc',
                                        paddingTop: '20px',
                                    }}
                                >
                                    Usted no posee ninguna localización configurada en su
                                    cuenta.<br></br>
                                    Por favor, configure al menos una localización para poder
                                    continuar.
                                    <NavLink
                                        className={classes.navLink}
                                        to={`/configuracion-organizacion`}
                                    >
                                        [link]
                                    </NavLink>
                                </h2>
                            )}
                            <List component="div" disablePadding dense>
                                {nodeList()}
                            </List>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>

                        {blueBar()}

                        <Box component="label" display="block" className={classes.label}>
                            Etiquetas
                        </Box>

                        <List
                            className={classes.containerTags}
                            component="div" disablePadding dense>
                            <List
                                component="div"
                                disablePadding
                                dense
                                className={classes.scrollableContainer}
                            >
                                {labelsList()}
                            </List>
                        </List>

                    </Grid>
                </Grid>

                {blueBar()}

                {/* Botones */}
                <Box display="flex" justifyContent="center">
                    <Button
                        className={classes.devicesBtn}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitSelectionItem}
                        disabled={selectedNodes.length === 0}
                    >
                        {!editingId ? 'Agregar' : 'Editar'}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCloseDevicesDialog}
                        className={classes.devicesBtn}
                    >
                        Cancelar
                    </Button>
                </Box>

            </Box>
        </Dialog>
    )
}