import React, { useEffect, useState, useContext } from 'react';
import {
  Box, Button, Chip, CircularProgress, Dialog, DialogContent,
  Grid, IconButton, makeStyles, Tooltip
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TuneIcon from '@material-ui/icons/Tune';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {
  formatDateObjToSlash,
  getControlDay,
  getNextWeekMaxToday,
} from '../../helpers/common';
import Container from '../Elements/Container';
import TopNav from '../common/TopNav';
import Filter from './Filter';
//Funcion que trae LocalStorage
import { getInstantV2 } from '../../services/meters';
import { getUserInfoFromLocalStorage } from '../../services/auth';
// import { getLabelText } from './helpers';
//plan
import UserContext from '../../context/UserContext';
import { FILTER_INITIAL_VALUES } from './helpers';
import DualChartAdvanced from './DualChartAdvanced';

const useStyles = makeStyles(theme => ({
  dateContainer: {
    padding: '0 8px',
    color: theme.palette.common.white,
    fontSize: 16,
    '& .MuiButtonBase-root': {
      color: theme.palette.common.white,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.common.white,
      width: 125,
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  date: {
    '& .MuiInputBase-root': {
      width: 150,
    },
  },
  nextDayBtn: {
    '&.Mui-disabled': {
      opacity: '0.5',
    },
  },
  btn: {
    margin: theme.spacing(2, 1),
    '& .MuiButton-label': {
      textTransform: 'none',
      // fontSize: 11,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  btnRefresh: {
    '&.MuiButton-root': {
      height: '30px',

      marginTop: '16px',
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiButton-label': {
      textTransform: 'none',
      paddingLeft: '5px',
      // fontSize: 11,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '@media (max-width: 700px)': {
      marginBottom: '10px',
      marginRight: '10px',
    },
  },
  devicesText: {
    marginLeft: theme.spacing(6),
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '200px',
    },
  },
  alert: {
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(0),
    },
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  nodosBoxesLabels: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: "wrap",
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  paper: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#cfd1d582',
      borderRadius: '8px',
    },
    '& .MuiPickersDay-dayDisabled': {
      backgroundColor: '#ff000000',
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: '#ff000000',
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#2586bc',
    },
  },
  dialogStyle: {
    '& .MuiDialog-paperScrollPaper': {
      height: '100%'
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 900px)': {
        padding: '2px'
      },
    },
    '& .MuiDialog-paper': {
      '@media (max-width: 600px)': {
        margin: '0px'
      },
    },
    '& .MuiDialog-paperFullWidth': {
      '@media (max-width: 600px)': {
        width: '100%'
      },
    },
  }
}));

export default function InstantAdvanced() {
  const classes = useStyles();
  const { plan } = useContext(UserContext);


  const [filterOptions, setFilterOptions] = useState(FILTER_INITIAL_VALUES);
  const [filterPanel, setFilterPanel] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showAlertOption, setShowAlertOption] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [limitDate, setLimitDate] = useState({
    inst_days_historic: 10,
  });
  const [instantData, setInstantData] = useState([]);
  const [devicesNames, setDevicesNames] = useState(null);
  const [tz, setTz] = useState('America/Argentina/Buenos_Aires');

  //Valida el timeZone
  useEffect(() => {
    setTz(plan?.info?.tz)
  }, [plan])

  //VARIABLES DE CATALOGO.
  const [varsCatalog, setVarsCatalog] = useState([]);
  const VARS_CATALOGO = plan?.sections["INSTANTANEOS"].filter(item => item.enabled);

  useEffect(() => {
    let array = VARS_CATALOGO?.map((elem) => {
      return {
        label: elem.tag ? elem.tag : elem.name,
        value: elem.key,
        fields: elem.fields,
        periods: elem.periods,
        phase: elem.phase,
        node: elem.node
      };
    });
    setVarsCatalog(array)
  }, [plan])

  //Red alert
  useEffect(() => {
    if (showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 7000);
    }
  }, [showErrorAlert])
  //Aca determino cuantos dias hacia atras puede pedir data el usuario  pra evitar consultas inenecesarias al back.
  useEffect(() => {
    setLimitDate(getUserInfoFromLocalStorage());
  }, []);

  //Funcion para buscar los labels de las variables de el catalogo.
  const getLabelText = value => {
    // Cubro caso en el que grafico variable principal y luego vuelvo al gráfico y copio dispositivos 
    if (!value)
      return ''

    const object = varsCatalog?.find(option => option.value === value);
    return object.label;
  };

  const togglePanel = () => {
    setFilterPanel(!filterPanel);
  };

  const handleSubmit = async filter => {
    if (filter) {
      setFilterPanel(false);
      setErrorMessage('');
      setFilterOptions(filter);
      let devices = [];
      const fetchPromises = [];

      filter.groups.forEach((group, groupIndex) => {
        const from = formatDateObjToSlash(group.selectedInitialDate);
        const to = formatDateObjToSlash(group.selectedEndDate);
        group.dataSets.forEach((dataset, datasetIndex) => {
          let url = '';
          let selectedNodeId = dataset?.devices[0]?.id;
          let selectedTagId = dataset?.devices[0]?.label_id;
          let variable = VARS_CATALOGO?.find(item => item.key === dataset.variable);
          dataset.variableName = variable.name
          console.log('variable', variable);
          
          // Asigna dispositivo con etiqueta si se seleccionó
          url += `filter=${selectedNodeId}`;
          if (selectedTagId) {
            url += `+${selectedTagId}`;
            let nameDevice = `${dataset?.devices[0]?.nombre} ${dataset?.devices[0]?.label_nombre}`
            devices.push(nameDevice)
          } else {
            let nameDevice = `${dataset?.devices[0]?.nombre}`
            devices.push(nameDevice)
          }

          // Asigna variable
          url += `&fields=${variable.fields}`;
          // Asigna granularidad
          url += `&granularity=${parseInt(dataset?.granularity)}`;
          // Asigna fecha desde/hasta
          url += `&from=${from}&to=${to}`;
          url += `&response_format=v2`;
          // Asigna franja horaria
          url += dataset.timeslots ? `&timeslots=${dataset.timeslots}` : '';
          // Si selecciono variable por fase la asigna
          url += filter.showPhase ? `&showPhase=true` : '';

          fetchPromises.push(
            fetchInstant(
              url,
              dataset,
              groupIndex,
            )
          );
        })
      });

      setLoading(true);
      try {
        const results = await Promise.all(fetchPromises);
        const successfulResults = results.filter(data => data !== null);

        const groupedArray = Object.values(
          successfulResults.reduce((acc, item) => {
            if (!acc[item.groupIndex]) {
              acc[item.groupIndex] = []; // Inicializa un nuevo grupo si no existe
            }
            acc[item.groupIndex].push(item); // Agrega el objeto al grupo correspondiente
            return acc;
          }, {})
        );

        setInstantData(groupedArray)
        setDevicesNames(devices)
        if (successfulResults.length === 0) {
          // Todas las solicitudes fallaron
          setErrorMessage('Ha ocurrido un error');
          setShowErrorAlert(true);
          setInstantData([]);
        } else {
          // Mezclar los datos exitosos
          const mergedData = successfulResults.reduce((acc, data) => acc.concat(data), []);
          setInstantData(mergedData);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
        setInstantData([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchInstant = async (
    url,
    dataset,
    groupIndex,
  ) => {
    //hace esto por cada dataset
    try {
      const res = await getInstantV2(url);
      if (res.data.error) {
        return []; // Retorna un array vacío si hay error
      } else {
        setShowErrorAlert(false);
        let data = res.data[0]?.data;
        let medidores = []
        if(res.data[0]?.medidores){
          medidores = [...res.data[0]?.medidores]
        }
        let resData = {
          ...dataset,
          data,          
          groupIndex,
          medidores
        }
        console.log({ resData });

        return resData;
      }
    } catch (error) {
      setInstantData([]);
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage(
            `No se encontraron datos para el período ${dataset.alias}.`
          );
        } else {
          setErrorMessage(`El dataset ${dataset.alias} devolvió un error.`);
        }
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
      setShowErrorAlert(true);
      return []; // Retorna un array vacío en caso de error
    }
  };

  //Hanle date del filtro Este handle solamente maneja el date de el filtro el cual se activa con el boton consultar
  // const handleDateChange = (date, field, topNav) => {
  //   let differenceBetweens =
  //     format(date, 'MM/dd/yyyy') <
  //     getControlDay(
  //       filterOptions.selectedEndDate,
  //       limitDate?.menu?.inst_days_request
  //     );
  //   //Este if es para q ue si la primer fecha DESDE es mayor a la segunda fecha HASTA  la iguale a primera.
  //   if (
  //     field === 'selectedInitialDate' &&
  //     date > filterOptions.selectedEndDate
  //   ) {
  //     setFilterOptions({
  //       ...filterOptions,
  //       selectedEndDate: date,
  //       [field]: date,
  //     });
  //     if (topNav === 'nav') {
  //       handleSubmit({
  //         ...filterOptions,
  //         selectedEndDate: date,
  //         [field]: date,
  //       });
  //     }
  //   }
  //   //Este if es para q si la fecha DESDE es inferior a la cantidad de dias q tiene eñ usuario con respecto a HASTA calendario.
  //   else if (
  //     field === 'selectedInitialDate' &&
  //     differenceBetweens &&
  //     date < filterOptions.selectedEndDate
  //   ) {
  //     //Aca cambio la fecha de nuevo a formato Wed Sep 21 2022 17:38:00 GMT-0300 (Argentina Standard Time)
  //     let aux = new Date(
  //       getNextWeekMaxToday(date, limitDate?.menu?.inst_days_request)
  //     );
  //     setFilterOptions({
  //       ...filterOptions,
  //       selectedEndDate: aux,
  //       [field]: date,
  //     });
  //     if (topNav === 'nav') {
  //       handleSubmit({
  //         ...filterOptions,
  //         selectedEndDate: date,
  //         [field]: date,
  //       });
  //     }
  //   } else {
  //     setFilterOptions({
  //       ...filterOptions,
  //       [field]: date,
  //     });
  //     if (topNav === 'nav') {
  //       handleSubmit({
  //         ...filterOptions,
  //         [field]: date,
  //       });
  //     }
  //   }
  // };

  const handleClose = () => {
    setFilterPanel(false);
  };

  const isFuture = someDate => {
    const today = new Date();
    return someDate > today;
  };
  return (
    <>
      <TopNav
        titulo="Analisis"
        subtitulo="| Instantáneos"
        changeTitleStyleFlex={true}
      >
        <Grid container justifyContent="flex-end">
          {/* {limitDate && (
            <Box
              className={classes.dateContainer}
              display="flex"
              justifyContent="center"
            >
              <IconButton
                aria-label="período anterior"
                onClick={decrementPeriod}
                disabled={loading}
              >
                <NavigateBeforeIcon />
              </IconButton>

              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.date}
                  readOnly={true}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  // id='date-picker-inline'
                  // label='Date picker inline'
                  value={filterOptions.selectedInitialDate}
                  onChange={e =>
                    handleDateChange(e, 'selectedInitialDate', 'nav')
                  }
                  minDate={getControlDay(
                    new Date(),
                    limitDate?.menu?.inst_days_historic
                  )}
                  maxDate={new Date()}
                  maxDateMessage=""
                  minDateMessage=""
                  KeyboardButtonProps={{
                    'aria-label': 'cambiar fecha',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}

                // PopoverProps={{ classes: classes }}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  readOnly={true}
                  className={classes.date}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  // id='date-picker-inline'
                  // label='Date picker inline'
                  value={filterOptions.selectedEndDate}
                  onChange={e => handleDateChange(e, 'selectedEndDate', 'nav')}
                  KeyboardButtonProps={{
                    'aria-label': 'cambiar fecha',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  minDate={new Date(filterOptions.selectedInitialDate)}
                  maxDate={getNextWeekMaxToday(
                    filterOptions.selectedInitialDate,
                    limitDate?.menu?.inst_days_request
                  )}
                  maxDateMessage=""
                  minDateMessage=""
                // PopoverProps={{ classes: classes }}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                className={classes.nextDayBtn}
                aria-label="período siguiente"
                onClick={incrementPeriod}
                disabled={isFuture(filterOptions.selectedEndDate) || loading}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          )} */}

          <Button
            className={classes.btn}
            type="button"
            onClick={togglePanel}
            size="small"
            startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
          >
            | Filtrar
          </Button>

          {/* Boton refresh */}
          <Tooltip
            title="Última consulta."
            arrow
          >
            <Button
              className={classes.btnRefresh}
              type="button"
              onClick={() => handleSubmit(filterOptions)}
              // size='small'
              startIcon={<FlipCameraAndroidIcon />}
            ></Button>
          </Tooltip>

        </Grid>
      </TopNav>


      <Dialog
        className={classes.dialogStyle}
        open={filterPanel}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        disableScrollLock
      >
        {/* <DialogTitle id="form-dialog-title">Filtrar</DialogTitle> */}
        <DialogContent>
          <Filter
            handleClose={handleClose}
            varsCatalogo={varsCatalog}
            onSubmit={handleSubmit}
            filter={filterOptions}
            setFilterPanel={setFilterPanel}
            limitDate={limitDate}
            setFilterOptions={setFilterOptions}
            getNextWeekMaxToday={getNextWeekMaxToday}
          />
        </DialogContent>
      </Dialog>

      <Container>
        {showErrorAlert ? (
          <Box className={classes.alert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  // color='error'
                  size="small"
                  onClick={() => {
                    setShowErrorAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Box>
        ) : null}

        {filterOptions.groups[0].dataSets.length > 0 &&
          !showAlertOption ? (
          <>
            <Box className={classes.nodosBoxesLabels}>
              <p className={classes.devicesText}>Gráfico principal:</p>
              {filterOptions.groups[0].dataSets.map((device, indx) => (
                <Box key={indx} >
                  <Chip
                    className={classes.chip}
                    key={device.id}
                    icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                    label={`${device.node.nombre}  ${device.label.nombre ? `+ ${device.label.nombre}` : ''
                      }`}
                    clickable
                    color="primary"
                    onClick={() => setFilterPanel(true)}
                  />
                  <Chip
                    className={classes.chip}
                    label={
                      <Box component="span">
                        <strong>Variable:</strong>{' '}

                        {getLabelText(filterOptions?.variable)}
                      </Box>
                    }
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => setFilterPanel(true)}
                  />
                </Box>
              ))}
            </Box>
            <Box className={classes.nodosBoxesLabels}>
              {
                filterOptions.groups[1].dataSets.length > 0 &&
                <p className={classes.devicesText}>Gráfico secundario:</p>
              }
              {
                filterOptions.groups[1].dataSets?.map(device => (
                  <Box key={device.id}>
                    <Chip
                      className={classes.chip}
                      icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                      label={`${device.node.nombre}  ${device.label.nombre ? `+ ${device.label.nombre}` : ''
                        }`}
                      clickable
                      color="secondary"
                      onClick={() => setFilterPanel(true)}
                    />
                    <Chip
                      className={classes.chip}
                      label={
                        <Box component="span">
                          <strong>Variable:</strong>{' '}
                          {getLabelText(filterOptions?.variable2)}
                        </Box>
                      }
                      color="secondary"
                      variant="outlined"
                      clickable
                      onClick={() => setFilterPanel(true)}
                    />
                  </Box>
                ))}
            </Box>
          </>
        ) : (
          <Alert className={classes.alert} severity="info">
            Bienvenido a Instantáneos. En esta sección podrá consultar la
            información de consumos instantáneos de sus dispositivos. Para
            comenzar haga click en el botón
            <span className={classes.tuneIcon}>
              <TuneIcon />
            </span>
            , ingrese los parámetros y haga click en CONSULTAR.
          </Alert>
        )}
        {loading ? (
          <div className={classes.circular}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {instantData && (
              <DualChartAdvanced
                tz={tz}
                chartData={instantData}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
}
