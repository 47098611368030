import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
//Icons
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
//Material ui.
import {
    Box, Chip, FormControl, IconButton,
    InputLabel, Select, TextField, MenuItem,
    Step, StepLabel, Stepper,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import clsx from 'clsx';
//Componentes
import DynamicSelect from '../common/DynamicSelect';
import SelectNodeTag from './SelectNodeLabel';
//Hooks
import useDeviceDetect from '../../hooks/useDeviceDetect';
//helpers.
import { blueBar } from '../../helpers/common'
//plan
import UserContext from '../../context/UserContext';
import { debounce } from 'lodash';
import TimeSlotSelector from '../common/TimeSlotSelector';

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        ...scrollsSettings,
        height: '60%',
        '@media (max-width: 920px)': {
            height: '70%',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
        '& .MuiStepper-root': {
            padding: '1px',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: theme.spacing(0.5),
        },
    },
    steppersFilter: {
        '&.MuiStep-horizontal': {
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
    selected: {
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            martinRight: '20px',
        },
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    addBtn: {
        margin: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    filterChip: {
        margin: theme.spacing(0.5),
        '@media (max-width: 700px)': {
            width: '250px',
        },
    },
    btn: {
        margin: theme.spacing(3, 1),
        '@media (max-width: 700px)': {
            fontSize: '13px',
            padding: '1%',
        },
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(3),
    },
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        minWidth: '25px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    active: {
        backgroundColor: theme.palette.primary.main,
    },
    help: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        padding: theme.spacing(0.5)
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 16,
        color: theme.palette.primary.dark,
    }
}));

export default function DatasetFilter({ initialDataset, indexGroupSelected, indexDataSetSelected, handleDataSetChanges, setOpenDataSetFilter }) {
    const classes = useStyles();
    const blueLine = blueBar()
    const { plan } = useContext(UserContext);
    const { isMobile } = useDeviceDetect(990);
    const [activeStep, setActiveStep] = useState(0);
    const [showDevices, setShowDevices] = useState(false);
    const [nodeLabelSelected, setNodeLabelSelected] = useState({});
    const steps = [
        'Elija el/los dispositivos gráfico principal',
        'Elija la variable principal a graficar',
        'Elija la granularidad',
        'Elija si desea mostrar un gráfico secundario',
        'Elija los días que desea graficar',
        'Haga click en CONSULTAR',
    ];

    const [dataSet, setDataSet] = useState(initialDataset)
    const [disabledTimeSlot, setDisabledTimeSlot] = useState(true);
    const enabledItems = plan?.sections['INSTANTANEOS']?.filter(
        item => item.enabled
    );
    const objectCatalog = {};
    enabledItems.forEach(item => {
        objectCatalog[item.key] = {
            key: item.key,
            name: item.tag ? item.tag : item.name,
            fields: item.fields,
            periods: item.periods,
            phase: item.phase,
        };
    });

    useEffect(() => {        
        if (dataSet.timeslots) {
            setDisabledTimeSlot(false)
        }
    }, [])

    useEffect(() => {
        if (nodeLabelSelected?.selectedNodes) {
            let devices = nodeLabelSelected?.selectedNodes
            if (nodeLabelSelected.selectedLabel) {
                let { id, nombre } = nodeLabelSelected.selectedLabel
                devices[0].label_id = id
                devices[0].label_nombre = nombre
            }
            if (!dataSet?.devices) {
                setActiveStep(3);
            }
            setDataSet(prevDataset => ({
                ...prevDataset,
                devices,
                color: devices?.length === 1 ? devices[0]?.color : '',
                alias: devices?.length === 1 ? devices[0]?.nombre : ''
            }));
        }
    }, [nodeLabelSelected]);


    const handleOpenCloseSelectNodeTag = (type) => {
        console.log(type)
        setShowDevices(!showDevices)
    }

    const handleFilterChange = e => {
        const { name, value } = e.target;
        setDataSet({
            ...dataSet,
            [name]: value,
        });
    };

    const handleSubmit = () => {    
        handleDataSetChanges(dataSet, indexDataSetSelected, indexGroupSelected)
        setOpenDataSetFilter(false)
    }

    const handleColorChange = debounce(value => {
        setDataSet({
            ...dataSet,
            color: value,
        });
        setActiveStep(4);
    });

    const handleDeleteDevice = (index) => {
        let devices = [...dataSet.devices]
        devices.splice(index, 1)
        setDataSet({
            ...dataSet,
            devices: devices,
        });
    }


    const handleSetTimeSlots = (timeslots) => {
        console.log(timeslots);
        setDataSet(prevDataset => ({
            ...prevDataset,
            timeslots: timeslots
        }));
    }

    return (
        <>
            <SelectNodeTag
                showDevices={showDevices}
                setNodeLabelSelected={setNodeLabelSelected}
                handleOpenCloseSelectNodeTag={handleOpenCloseSelectNodeTag}
            />

            <Stepper
                className={classes.steppersFilter}
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map(label => (
                    <Step className={classes.steppersFilter} key={label}>
                        <StepLabel>{isMobile ? '' : label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {blueLine}

            {/* CONTAINER */}
            <Box className={classes.root}>
                <Box>
                    {/* STEP 1 */}
                    <Box
                        m={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip
                            title="Elija el/los dispositivos que desea añadir al gráfico principal haciendo click en el botón +"
                            arrow
                            placement="right"
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 0, }, { [classes.tick]: activeStep > 0, })}              >
                                1
                            </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel}>
                            Agregar dispositivo
                        </Box>
                        <IconButton
                            aria-label="Agregar dataset"
                            className={classes.addBtn}
                            onClick={() => handleOpenCloseSelectNodeTag()}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                        {dataSet?.devices?.map((node, index) =>
                        (
                            <Chip
                                key={node.id}
                                icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                                label={`${node.nombre}${node.label_nombre ? ` + ${node.label_nombre}` : ''}`}
                                clickable
                                style={{ marginLeft: '10px', marginTop: '5px' }}
                                color="primary"
                                onClick={() => handleOpenCloseSelectNodeTag('edit')}
                                onDelete={() => handleDeleteDevice(index)}
                                className={classes.filterChip}
                            />
                        )
                        )}
                    </Box>

                    {blueLine}

                    <Box
                        m={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip
                            title='Elija el titulo q desea para su componente'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 0, }, { [classes.tick]: activeStep > 0, })}              >
                                2
                            </Box>
                        </Tooltip>

                        <Box className={classes.devicesLabel}>
                            <Box className={classes.devicesLabel}>
                                Alias
                            </Box>
                            <TextField
                                className={classes.input}
                                autoFocus
                                type='text'
                                name='alias'
                                value={dataSet?.alias}
                                onChange={handleFilterChange}
                                color='primary'
                            />
                        </Box>
                    </Box>

                    {blueLine}

                    <Box display="flex" alignItems="center" flexWrap="wrap" m={2}>
                        <Tooltip title="Color" arrow placement="right">
                            <Box
                                className={clsx(
                                    classes.hint,
                                    classes.leftSpacing,
                                    { [classes.active]: activeStep === 2 },
                                    { [classes.tick]: activeStep > 2 || dataSet?.color }
                                )}
                            >
                                3
                            </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel}>Seleccione un color</Box>
                        <TextField
                            style={{ width: '40px', marginLeft: '15px' }}
                            type="color"
                            value={dataSet?.color || ''}
                            name="color1"
                            onChange={e => handleColorChange(e.target.value)}
                            disabled={dataSet?.devices?.length > 1}
                        />
                    </Box>

                    {blueLine}

                    <Box
                        m={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip
                            title="Elija el/los dispositivos que desea añadir al gráfico principal haciendo click en el botón +"
                            arrow
                            placement="right"
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 0, }, { [classes.tick]: activeStep > 0, })}              >
                                4
                            </Box>
                        </Tooltip>
                        <Box mt={1} width={'80%'}>
                            <DynamicSelect
                                handleChangeProp={handleFilterChange}
                                valueSelect={dataSet?.variable ? dataSet?.variable : ''}
                                keyName={'variable'}
                                InputLabelProp={'Variable a graficar'}
                                section={'INSTANTANEOS'}
                            />
                        </Box>
                        {/* {
                            [].length === 0 &&
                            <Box className={classes.help}>Debe elegir al menos un dispositivo secundario antes de elegir la variable.</Box>
                        } */}
                    </Box>
                </Box>

                {blueLine}

                <Box >
                    <Box
                        m={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip
                            title="Elija la granularidad"
                            arrow
                            placement="right"
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, {
                                [classes.active]: true,
                            }, { [classes.tick]: true !== '', })}
                            >
                                5
                            </Box>
                        </Tooltip>
                        <FormControl style={{ width: '72%' }}>
                            <InputLabel id="granularityLabel" shrink>Granularidad</InputLabel>
                            <Select
                                labelId="granularityLabel"
                                name="granularity"
                                value={dataSet?.granularity ? dataSet?.granularity : ''}
                                onChange={handleFilterChange}
                            >
                                {objectCatalog[dataSet?.variable]?.periods.map(option => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {/* {
                        filter.variable === '' &&
                        <Box className={classes.help}>Debe elegir una variable antes de elegir la granularidad.</Box>
                    } */}
                </Box>

                {blueLine}

                <Box>
                    <Box m={2} display="flex" alignItems="start">
                        <Tooltip
                            title="Elija la granularidad"
                            arrow
                            placement="right">
                            <Box className={clsx(classes.hint, classes.leftSpacing, {
                                [classes.active]: true,
                            }, { [classes.tick]: true !== '', })}
                            >
                                6
                            </Box>
                        </Tooltip>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="showSecondary"
                                    checked={!disabledTimeSlot}
                                    onChange={() => setDisabledTimeSlot(!disabledTimeSlot)}
                                    color="primary"
                                />
                            }
                        />
                        <TimeSlotSelector
                            timeSlots={dataSet.timeslots}
                            disabledTimeSlot={disabledTimeSlot}
                            handleSetTimeSlots={handleSetTimeSlots}
                        />
                    </Box>
                </Box>

                {/* Botones */}
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        className={classes.btn}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Guardar
                    </Button>

                    <Button
                        className={classes.btn}
                        variant="contained"
                        onClick={() => setOpenDataSetFilter(false)}
                    >
                        Cancelar
                    </Button>
                </Box>
            </Box>
        </>
    );
}
