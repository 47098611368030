import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

export default function TimeSlotSelector({ timeSlots, disabledTimeSlot, handleSetTimeSlots }) {
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedHoursTimeSlot, setHoursTimeSlot] = useState({
        time_from: '00:00',
        time_to: '00:00',
        step: 900,
    });

    useEffect(() => {
        getTimeSlotsValues();
    },[])

    useEffect(() => {
        const buildTimeSlot = () => {
            const timeslots = `${selectedDays}=${selectedHoursTimeSlot?.time_from}a${selectedHoursTimeSlot?.time_to}`;            
            handleSetTimeSlots(disabledTimeSlot ? '' : timeslots)
        };

        buildTimeSlot();
    }, [selectedDays, selectedHoursTimeSlot, disabledTimeSlot]);

    const getTimeSlotsValues = () => {
        if (timeSlots) {
            let timeslotsAux = timeSlots;
            let elements = timeslotsAux?.split('=')[0];
            // Separa los elementos por coma
            let days = elements.split(',');
            setSelectedDays(days);
            // Separa la parte después del '='
            let hours = timeslotsAux.split('=')[1];
            // Separa los tiempos por la 'a'
            let [time_from, time_to] = hours.split('a');

            setHoursTimeSlot({
                time_from,
                time_to,
            });
        }
    };

    const handleTimeSlot = event => {
        const { value, checked } = event.target;
        if (checked) {
            // Si se selecciona, agregamos el día al array
            setSelectedDays([...selectedDays, value]);
        } else {
            // Si se deselecciona, eliminamos el día del array
            setSelectedDays(selectedDays.filter(day => day !== value));
        }
    };

    const handleHoursTimeSlot = event => {
        const { value, name } = event.target;
        setHoursTimeSlot({
            ...selectedHoursTimeSlot,
            [name]: value,
        });
    };

    return (
        <Box>
            <Box>
                <FormControl disabled={disabledTimeSlot}>
                    <FormGroup row>
                        {['lu', 'ma', 'mi', 'ju', 'vi', 'sa', 'do'].map(elem => (
                            <FormControlLabel
                                key={elem}
                                control={
                                    <Checkbox
                                        value={elem}
                                        checked={selectedDays.includes(elem)}
                                        onChange={handleTimeSlot}
                                        color="primary"
                                    />
                                }
                                label={elem}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Box>

            <Box mt={1} display={'flex'}>
                {/* DESDE */}
                <Box>
                    <TextField
                        name="time_from"
                        disabled={disabledTimeSlot}
                        type="time"
                        label="Desde:"
                        size="small"
                        variant="outlined"
                        value={selectedHoursTimeSlot?.time_from}
                        onChange={e => handleHoursTimeSlot(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: selectedHoursTimeSlot?.step,
                            pattern: '[0-9]{2}:[0-9]{2}',
                        }}
                        onInput={e => {
                            const input = e.target;
                            let value = input.value;
                            // Solo bloquear los minutos si el step es 3600 (1 hora)
                            if (selectedHoursTimeSlot?.step === 3600 && value) {
                                const [hours] = value.split(':');
                                input.value = `${hours}:00`; // Fija los minutos a 00
                                handleHoursTimeSlot(e); // Llama a la función de manejo
                            } else {
                                // Si el step no es 3600, permitir la modificación normal
                                handleHoursTimeSlot(e);
                            }
                        }}
                    />
                </Box>
                {/* HASTA */}
                <Box ml={2}>
                    <TextField
                        name="time_to"
                        disabled={disabledTimeSlot}
                        type="time"
                        label="Hasta:"
                        size="small"
                        variant="outlined"
                        value={selectedHoursTimeSlot?.time_to}
                        onChange={e => handleHoursTimeSlot(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: selectedHoursTimeSlot?.step,
                            pattern: '[0-9]{2}:[0-9]{2}',
                        }}
                        onInput={e => {
                            const input = e.target;
                            let value = input.value;

                            // Solo bloquear los minutos si el step es 3600 (1 hora)
                            if (selectedHoursTimeSlot?.step === 3600 && value) {
                                const [hours] = value.split(':');
                                input.value = `${hours}:00`; // Fija los minutos a 00
                                handleHoursTimeSlot(e); // Llama a la función de manejo
                            } else {
                                // Si el step no es 3600, permitir la modificación normal
                                handleHoursTimeSlot(e);
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}