export const DATASET_INITIAL_VALUES = {
  devices: [],
  color: '',
  alias: '',
  timeslots: '',
  date: {},
};

export const FILTER_INITIAL_VALUES = {
  variable: '',
  granularity: '',
  showPhase:false,
  groups: [
    {
      disabled: false,
      datasets: [],
      selectedDate: new Date(),
    },
    {
      disabled: true,
      datasets: [],
      selectedDate: new Date(),
    },
    {
      disabled: true,
      datasets: [],
      selectedDate: new Date(),
    },
  ],
};

export const CALENDAR_INITIAL_CONFIG = {
  views: ['month', 'year'],
  disabled: false,
  format: 'MM/yyyy',
};