import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import {
  Box, Chip, Dialog, IconButton,
  Step, StepLabel, Stepper,
} from '@material-ui/core';
import clsx from 'clsx';
import DatePickerInstant from '../DatePicker/DatePickerInstant';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { blueBar, getControlDay } from '../../helpers/common'
import DatasetFilter from './DatasetFilter';
import UserContext from '../../context/UserContext';
import { DATASET_INITIAL_VALUES, FILTER_INITIAL_VALUES } from './helpers';
import { format } from 'date-fns';
import DatePickerCommon from '../DatePicker/DatePickerCommon';

let scrollsSettings = {
  overflow: 'scroll',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    ...scrollsSettings,
    height: '60%',
    '@media (max-width: 920px)': {
      height: '70%',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '& .MuiGrid-root': {
      padding: theme.spacing(1),
    },
    '& .MuiStepper-root': {
      padding: '1px',
    },
    '& .MuiFormControlLabel-root': {
      marginRight: theme.spacing(0.5),
    },
  },
  steppersFilter: {
    '&.MuiStep-horizontal': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      martinRight: '20px',
    },
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  devicesBtn: {
    margin: theme.spacing(1, 1),
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  // Usar clsx con addBtn
  addBtnSec: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },
  dialogTitle: {
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
    '@media (max-width: 700px)': {
      fontSize: '13px',
      padding: '1%',
    },
  },
  selectedItemsContainer: {
    height: '35px',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
  },
  labelDate: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  scrollableContainer: {
    height: 'max-content',
  },
  filterLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  containerTags: {
    ...scrollsSettings,
    height: '50vh',
    '@media (max-width: 960px)': {
      height: '15vh',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '& .MuiList-root.MuiList-dense': {
      // marginRight: "10px",
    },
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },
  containerNodes: {
    paddingRight: '5px',
    ...scrollsSettings,
    height: '50vh',
    '@media (max-width: 960px)': {
      height: '32vh',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '& .MuiList-root.MuiList-dense': {
      // marginRight: "10px",
    },
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },
  wordCut: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  wrapperDatepicker: {
    position: 'relative',
    width: '100%',
    left: '50%',
    transform: 'translate(-50%)',
    textAlign: 'center',
  },
  secondaryContainer: {
    border: `1px dashed ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    // padding: theme.spacing(1),
  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5)
  },
  fullWidth: {
    width: '80%',
  },
  granularity2Style: {
    width: '90%',
  },
  title: {
    padding: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.primary.dark,
  },
  containerGrapSettings: {
    minHeight: '34%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid #00000029', // Borde sólido negro de 1px
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));

export default function Filter({ handleClose, setFilterOptions, initialFilter, limitDate, getNextWeekMaxToday, onSubmit }) {
  const classes = useStyles();
  const blueLine = blueBar()
  const { isMobile } = useDeviceDetect(990);
  const [activeStep, setActiveStep] = useState(0);

  const [filter, setFilter] = useState(initialFilter ?? FILTER_INITIAL_VALUES)
  const [openDataSetFilter, setOpenDataSetFilter] = useState(false);
  const [indexDataSetSelected, setIndexDataSetSelected] = useState(null)
  const [indexGroupSelected, setIndexGroupSelected] = useState(null)

  const steps = [
    'Configure gráfico principal',
    'Configure gráfico secundario (Opcional)',
    'Haga click en CONSULTAR',
  ];
  const datasetInitialValues = DATASET_INITIAL_VALUES
  const [date, setDate] = useState(null)

  useEffect(() => {
    setFilterOptions(filter);
  }, [filter, setFilter]);

  const handleOpenDataSet = (groupIndex, datasetIndex) => {
    setIndexGroupSelected(groupIndex)
    setIndexDataSetSelected(datasetIndex)
    setOpenDataSetFilter(!openDataSetFilter)
  }

  const handleDeleteDataSet = (groupIndex, datasetIndex) => {
    let filterAux = { ...filter }
    filterAux.groups[groupIndex].dataSets.splice(datasetIndex, 1)
    setFilter(filterAux)
  }

  const handleSubmit = () => {
    onSubmit(filter)
  }

  const handleDataSetChanges = (dataSet, indexDataSet, indexGroup) => {
    let filterAux = { ...filter }
    let existentDataset = filterAux.groups[indexGroup].dataSets[indexDataSet]
    if (existentDataset) {
      filterAux.groups[indexGroup].dataSets[indexDataSet] = dataSet
    } else {
      filterAux.groups[indexGroup].dataSets.push(dataSet)
    }
    setFilter(filterAux)
  }

  const handleDateChange = (dateStart, dateEnd, indexGroup) => {
    let filterAux = {...filter}
    filterAux.groups[indexGroup].selectedInitialDate = dateStart
    filterAux.groups[indexGroup].selectedEndDate = dateEnd
    setFilter(filterAux)
  }

  return (
    <>
      <Stepper
        className={classes.steppersFilter}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map(label => (
          <Step className={classes.steppersFilter} key={label}>
            <StepLabel>{isMobile ? '' : label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {blueLine}

      <Dialog
        className={classes.dialogStyle}
        open={openDataSetFilter}
        onClose={() => setOpenDataSetFilter(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DatasetFilter
          initialDataset={filter?.groups[indexGroupSelected]?.dataSets[indexDataSetSelected] ?? datasetInitialValues}
          indexGroupSelected={indexGroupSelected}
          indexDataSetSelected={indexDataSetSelected}
          handleDataSetChanges={handleDataSetChanges}
          setOpenDataSetFilter={setOpenDataSetFilter}
        />
      </Dialog>
      {
        filter.groups.map((element, indexGroup) => {
          return (
            <Box key={indexGroup} className={classes.containerGrapSettings}>
              <Box >
                <Box>
                  <Box
                    className={classes.title}>
                    {element.title}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    minHeight={40}
                  >
                    <Tooltip
                      title="Elija el/los dispositivos que desea añadir al gráfico principal haciendo click en el botón +"
                      arrow
                      placement="right"
                    >
                      <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 0, }, { [classes.tick]: activeStep > 0, })}>
                        1
                      </Box>
                    </Tooltip>
                    <Box className={classes.devicesLabel}>
                      Agregar dataset
                    </Box>
                    <IconButton
                      aria-label="Agregar dataset"
                      className={classes.addBtn}
                      onClick={() => handleOpenDataSet(indexGroup)}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                    {element?.dataSets?.length > 0 &&
                      element?.dataSets.map((item, indexDataSet) => (
                        <Chip
                          key={item.id}
                          icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                          label={item.alias}
                          clickable
                          color="primary"
                          onClick={() => handleOpenDataSet(indexGroup, indexDataSet)}
                          onDelete={() => handleDeleteDataSet(indexGroup, indexDataSet)}
                          className={classes.filterChip}
                        />
                      ))}
                  </Box>
                </Box>

                <Box className={classes.wrapperDatepicker}>
                  <label className={classes.labelDate} >
                    Desde / Hasta
                  </label>
                  <DatePickerCommon
                    startDate={element.selectedInitialDate}
                    endDate={element.selectedEndDate}
                    handleDateChange={(dateStart, dateEnd) => handleDateChange(dateStart, dateEnd, indexGroup)}
                    setDate={setDate}
                  />
                </Box>
              </Box>
            </Box>
          )
        })
      }

      {/* Botones */}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.btn}
          onClick={handleSubmit}
        >
          Consultar
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          className={classes.btn}
        >
          Cancelar
        </Button>
      </Box>
    </>
  );
}
