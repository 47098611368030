import { useContext, useState, useEffect } from 'react';
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import { Box, makeStyles, Tooltip, IconButton } from '@material-ui/core';
//Hook personalizado.
import DiagramContext from '../Store/DiagramContext';
//Helper
import { getSettingBlock, getCatalogo } from '../helpers';
//Components.
import ModalSettings from '../ModalSettings';
//React flow
import { useEdges, useNodes, useReactFlow } from 'reactflow';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

let styleAux = {
    width: `85px`,
    height: `85px`,
}

const useStyles = makeStyles(theme => ({
    "@keyframes growShrink": {
        "0%": {
            transform: "scale(1)",
        },
        "50%": {
            transform: "scale(1.2)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
    "@keyframes rotateRight": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "10%": {
            transform: "rotate(10deg)",
        },
        "20%": {
            transform: "rotate(20deg)",
        },
        "30%": {
            transform: "rotate(30deg)",
        },
        "40%": {
            transform: "rotate(40deg)",
        },
        "50%": {
            transform: "rotate(55deg)",
        },
        "60%": {
            transform: "rotate(60deg)",
        },
        "70%": {
            transform: "rotate(70deg)",
        },
        "80%": {
            transform: "rotate(80deg)",
        },
        "90%": {
            transform: "rotate(90deg)",
        },
    },
    root: {
        ...styleAux,
        padding: '3px',
        backgroundColor: '#f5f5f5',
        border: `1px solid ${`black`}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    disabledStyle: {
        ...styleAux,
        pointerEvents: 'none',
        opacity: 0.5,
        backgroundColor: '#f0f0f0',
        color: '#a0a0a0',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    animationRotate: {
        // animation: "$growShrink 0.2s ease forwards",
        animation: "$rotateRight 0.1s ease forwards",
    }
}));

function ContainerBlockGenericType
    ({ id, isConnectable, type, selected, labelBlock,
        flagNumber, showLabelInput, adderFlag, userConfiguration, comparatorLetters,
    }) {
    const classes = useStyles();
    const edges = useEdges();
    const nodes = useNodes()
    const updateNodeInternals = useUpdateNodeInternals();
    const { setNodes } = useReactFlow();
    const { stateDiagram, setStateDiagram } = useContext(DiagramContext)
    const [blockNodeFilter, setBlockNodeFilter] = useState({});
    const [blockNodeSettings, setBlockNodeSettings] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [disabledNode, setDisabledNode] = useState(false);
    const [errorNode, setErrorNode] = useState(false);
    const [sourcePosition, setSourcePosition] = useState(Position.Right)
    const [targetPosition, setTargetPosition] = useState(Position.Left)
    let flagNumberTopInputs = flagNumber

    //Estado para buscar los datos de la propiedad 'a' para mostrar en el bloque.
    useEffect(() => {
        let findElement = nodes.find((elem) => elem.id === id)
        setBlockNodeFilter(findElement)
        //Cargo rotacion si el bloque fue modificado.
        if (findElement?.sourcePosition || findElement?.targetPosition) {
            setStyleChange(findElement.styleChange)
            setSourcePosition(findElement.sourcePosition)
            setTargetPosition(findElement.targetPosition)
        }
        // eslint-disable-next-line
    }, [nodes])

    useEffect(() => {
        let res = getSettingBlock(type)
        setBlockNodeSettings(res)
        // eslint-disable-next-line
    }, [])

    //Deshabilita el nodo segun el nodo el cual se esta conectando.
    useEffect(() => {
        let res = stateDiagram?.disabledNodes?.includes(type)
        setDisabledNode(res)
        // eslint-disable-next-line
    }, [stateDiagram?.disabledNodes])

    // Cambia el bloque a modo error (En el bloque hay un error de configuración).
    useEffect(() => {
        let res = stateDiagram?.errorBlockNodes?.includes(id)
        setErrorNode(res)
        // eslint-disable-next-line
    }, [stateDiagram?.errorBlockNodes])

    const handleOpenModal = () => {
        //Guardo el estado de el modal para desactivar el cntr + V.
        setStateDiagram({
            ...stateDiagram,
            openModal: !openModal
        })
        setOpenModal(!openModal)
    }

    //Verifica en q ruta esta parado el user
    const chekUrlc = () => {
        //Validar estar en ruta 'dashboard-public'.
        const ruta = window.location.pathname;
        const resAux = ruta.split('/')[1]
        return resAux
    }

    const checkTargetConnect = (idAux, elementAux) => {
        let flag = true
        for (let i = 0; i < edges.length; i++) {
            const element = edges[i];
            if (element.target === idAux) {
                if (element.targetHandle === elementAux.key) {
                    flag = false
                }
            }
        }
        return flag
    }

    const validType = () => {
        if (type === 'OUTA' || type === 'OUTD') {
            return false
        }
        return true
    }

    const onDoubleClickAux = () => {
        if (!openModal) {
            handleOpenModal();
        }
    }

    //Retorna el label de el bloque CONST.
    const labelReturn_CONST_ = (value) => {
        let res = 'CONST'
        if (value?.a?.v !== undefined) {
            res = value?.a?.v?.toString()
        }
        if (value?.values) {
            res = value?.values
        }
        return res
    }

    const returnUnitString = (value) => {
        let show_unit = value?.show_unit
        let base_unit = value?.base_unit
        if (show_unit.length > 0) {
            return `(${show_unit})`
        }
        if (base_unit.length > 0) {
            return `(${base_unit})`
        }
        return ''
    }

    //Retorna el label de el bloque VAR.
    const labelReturn_VAR_ = (value) => {
        if (blockNodeFilter.values) {
            let keyVar = blockNodeFilter.values.split('-')[1]
            let res = getCatalogo('ALERTS_VARIABLES', 'detail_data', keyVar)
            return (
                <Box >
                    {/* Nombre o tag de variable */}
                    <Box textAlign={'center'} fontSize={'8px'}>
                        {res?.tag ? res?.tag : res.name}
                    </Box>
                    {/* Unidad de variable*/}
                    <Box mt={0.1} textAlign={'center'} fontSize={'7px'}>
                        {returnUnitString(res)}
                    </Box>
                </Box>
            )
        }
        return 'VAR'
    }

    const createArray = (n) => {
        return Array.from({ length: n }, (v, i) => i);
    }

    const handleFlip = (type) => {
        let objAux = {
            styleChange: true,
            sourcePosition: Position.Top,
            targetPosition: Position.Bottom
        }
        if (sourcePosition === 'left') {
            if (type !== 'right') {
                objAux = {
                    styleChange: true,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left
                }
            }
        }
        else {
            if (type !== 'left') {
                objAux = {
                    styleChange: true,
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right
                }
            }
        }
        setStyleChange(objAux.styleChange)
        setSourcePosition(objAux.sourcePosition)
        setTargetPosition(objAux.targetPosition)
        updateNodeInternals(id)
        let newArry = nodes.map((elem) => {
            if (elem.id === id) {
                elem.styleChange = objAux.styleChange
                elem.sourcePosition = objAux.sourcePosition
                elem.targetPosition = objAux.targetPosition
            }
            return elem
        })
        setNodes(newArry)
    }

    const [styleChange, setStyleChange] = useState(true)
    //Mueve los puntos y modifica los estilos durante el giro. 
    const handleRotate = () => {
        handleAnimation()
        let objAux = {}
        if (sourcePosition === 'left') {
            objAux = {
                styleChange: false,
                sourcePosition: Position.Top,
                targetPosition: Position.Bottom
            }
        }
        if (sourcePosition === 'top') {
            objAux = {
                styleChange: true,
                sourcePosition: Position.Right,
                targetPosition: Position.Left
            }
        }
        if (sourcePosition === 'right') {
            objAux = {
                styleChange: false,
                sourcePosition: Position.Bottom,
                targetPosition: Position.Top
            }
        }
        if (sourcePosition === 'bottom') {
            objAux = {
                styleChange: true,
                sourcePosition: Position.Left,
                targetPosition: Position.Right
            }
        }
        setStyleChange(objAux.styleChange)
        setSourcePosition(objAux.sourcePosition)
        setTargetPosition(objAux.targetPosition)
        updateNodeInternals(id)
        let newArry = nodes.map((elem) => {
            if (elem.id === id) {
                elem.styleChange = objAux.styleChange
                elem.sourcePosition = objAux.sourcePosition
                elem.targetPosition = objAux.targetPosition
            }
            return elem
        })
        setNodes(newArry)
    }

    //Modifica el estado del bloque cuando se realiza el giro.
    const returnStylePoints = (flagAux) => {
        if (!styleChange) {
            return {
                left: flagAux
            }
        }
        else {
            return {
                top: flagAux
            }
        }
    }

    //Modifica las letras de los bloques durante el giro.
    const returnStylelabels = () => {
        if (!styleChange) {
            if (sourcePosition === 'bottom') {
                return {
                    top: 5,
                    left: 1
                }
            }
            else {
                return {
                    bottom: 20,
                    position: 'absolute',
                    left: 1,
                    paddingBottom: 3
                }
            }
        }
        else {
            return {
                right: (targetPosition === 'left' ? '-8px' : (type === 'MOD' ? '13px' : '8px')),
            }
        }
    }

    //Funcion encargada copiar bloque.
    const onCopyBlock = () => {
        const lastElement = nodes[nodes.length - 1];
        let newId = Number(lastElement.id) + 1
        let copyNode = {
            ...blockNodeFilter,
            position: { x: 0, y: 0 }
        }
        copyNode.id = newId.toString()
        copyNode.position.x = blockNodeFilter.position.x + 16
        copyNode.position.y = blockNodeFilter.position.y + 16
        copyNode.selected = true
        if (copyNode.type === 'INA' || copyNode.type === 'IND' || copyNode.type === 'OUTD' || copyNode.type === 'OUTA') {
            copyNode.a = {}
        }
        let newNodes = nodes.map((elem) => {
            elem.selected = false
            return elem
        })
        let nodesAux = [
            ...newNodes,
            copyNode
        ]
        setNodes(nodesAux)
    }

    const [animate, setAnimate] = useState(false);
    //Maneja la animacion del giro del bloque.
    const handleAnimation = () => {
        setAnimate(true); // Activa la animación
        setTimeout(() => setAnimate(false), 200); // Desactiva el estado después de la duración de la animación
    };

    return (
        <>
            <Box className={animate ? classes.animationRotate : {}}
            >
                <Box
                    display={'flex'} flexDirection='column'
                    className={disabledNode ? classes.disabledStyle : classes.root}
                    key={id}
                    style={{
                        // transform: `rotate(${degRotate}deg)`,
                        border: selected ? `1px dashed ${errorNode ? '#f8000075' : '#2586bc'}` : `1px solid ${errorNode ? '#f8000075' : 'black'}`,
                        backgroundColor: errorNode ? 'rgba(248, 0, 0, 0.1)' : '',
                    }}
                >

                    {openModal &&
                        <ModalSettings
                            type={type}
                            blockNodeSettings={blockNodeSettings}
                            handleOpenModal={handleOpenModal}
                            openModal={openModal}
                            userConfiguration={userConfiguration}
                            id={id}
                        />
                    }
                    <Box
                        onDoubleClick={onDoubleClickAux}
                        display={'flex'} justifyContent={'center'}
                        style={{ width: '50px', display: 'flex' }}
                    >
                        {labelBlock ?
                            <Box  >
                                <Box mt={0.5} width='100%' fontSize={9} display={'flex'} justifyContent={'center'}>
                                    {`${type}`}
                                </Box>
                                {labelBlock === 'CONST' ?
                                    <Box display={'flex'} justifyContent={'center'}>
                                        {labelReturn_CONST_(blockNodeFilter)}
                                    </Box>
                                    :
                                    labelBlock === 'VAR' ?
                                        <Box>
                                            {labelReturn_VAR_(blockNodeFilter)}
                                        </Box>
                                        :
                                        <>
                                            {
                                                comparatorLetters ?
                                                    <Box display={'flex'} fontSize={'13px'} alignItems={'center'}>
                                                        <Box style={{ fontStyle: 'italic' }}>
                                                            l
                                                        </Box>
                                                        <Box m={0.6} fontSize={'12px'}>
                                                            {labelBlock}
                                                        </Box>
                                                        <Box style={{ fontStyle: 'italic' }}>
                                                            r
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Box fontSize={'13px'}>
                                                        {labelBlock}
                                                    </Box>
                                            }
                                        </>
                                }
                            </Box>
                            :
                            <Box display={'flex'} flexDirection='column'>
                                <Box mt={0.5} width='100%' fontSize={9} display={'flex'} justifyContent={'center'}>
                                    {`${type}`}
                                </Box>
                                <Box display={'flex'}>
                                    <img src={`/images/diagram/${type.toLowerCase()}.svg`} alt="" width="35" height="35" />
                                </Box>
                            </Box>
                        }
                    </Box>
                    {/* //Botones de giro */}
                    {
                        selected && chekUrlc() !== 'plc' &&
                        <Box height={'10px'} pb={blockNodeSettings?.inputs_keys?.length > 0 ? 2.5 : 0}>
                            <Box display='flex' justifyContent='center'  >
                                <Tooltip
                                    title={'Invertir'}
                                    arrow
                                    placement="right"
                                >
                                    <IconButton
                                        style={{
                                            width: '1px',
                                            height: '1px',

                                        }}
                                        onClick={() => handleFlip()}
                                        color="primary" >
                                        <CompareArrowsIcon style={{ fontSize: '10px', color: '#2586bcc4' }} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip
                                    title={'Duplicar'}
                                    arrow
                                    placement="right"
                                >
                                    <IconButton
                                        style={{
                                            width: '1px',
                                            height: '1px',
                                        }}
                                        onClick={onCopyBlock}
                                        color="primary" >
                                        <ContentCopyIcon style={{ fontSize: '10px', color: '#2586bcc4' }} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip
                                    title={'Girar'}
                                    arrow
                                    placement="right"
                                >
                                    <IconButton
                                        style={{
                                            width: '1px',
                                            height: '1px',
                                        }}
                                        onClick={() => handleRotate()}
                                        color="primary" >
                                        <RefreshIcon style={{ fontSize: '10px', color: '#2586bcc4' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    }


                    {validType() &&
                        <>
                            {createArray(blockNodeSettings?.outputs_min).map((elem, indx) => {
                                return (
                                    <Tooltip
                                        key={indx}
                                        title={'Salida'}
                                        arrow
                                        placement='right'
                                    >
                                        <Handle
                                            type="source"
                                            position={sourcePosition}
                                            isConnectable={isConnectable}
                                            id="1"
                                            style={{ width: 8, height: 8, backgroundColor: '#1d1c33d1' }}
                                        />
                                    </Tooltip>
                                )
                            })}
                        </>
                    }
                    <Box >
                        {(Array.isArray(blockNodeSettings?.inputs_keys) ? blockNodeSettings.inputs_keys : []).map((element, index) => {
                            let flagAux = flagNumberTopInputs
                            flagNumberTopInputs += adderFlag ? adderFlag : 11
                            return (
                                <Box key={element.key} >
                                    <Tooltip
                                        title={`${element?.name} ( ${element.key} )`}
                                        arrow
                                        placement='right'
                                    >
                                        <Handle
                                            key={index}
                                            type="target"
                                            position={targetPosition}
                                            id={element.key}
                                            isConnectable={disabledNode ? false : isConnectable}
                                            style={{
                                                ...returnStylePoints(flagAux),
                                                position: 'fixed',
                                                display: 'block',
                                                width: 7,
                                                height: 7,
                                                border: `1px solid ${`black`}`,
                                                backgroundColor: checkTargetConnect(id, element) ? 'white' : '#b7b4b4'
                                            }}
                                        >
                                            {showLabelInput &&
                                                <Box style={{
                                                    fontSize: '8px',
                                                    position: 'relative',
                                                    ...returnStylelabels(),
                                                    bottom: '2px',
                                                }}>
                                                    {element.key}
                                                </Box>
                                            }
                                        </Handle>
                                    </Tooltip>
                                </Box>
                            )
                        })}

                    </Box>

                </Box>
            </Box >


        </>
    );
}
export default ContainerBlockGenericType
