import React, { useEffect, useState, useContext } from 'react';
//Material ui.
import {
    makeStyles
} from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions, Typography, Button, List, ListItem, Divider, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
    planCard: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        maxWidth: '300px',
        fontFamily: 'Arial, sans-serif',
    },
    planHeader: {
        backgroundColor: '#f5f5f5',
        padding: '8px',
        borderRadius: '8px 8px 0 0',
        textAlign: 'center',
    },
    planFeatures: {
        listStyle: 'none',
        padding: 0,
        margin: '16px 0',
    },
    planFeatureItem: {
        marginBottom: '8px',
    },
    planPrice: {
        textAlign: 'center',
    },
    planButton: {
        display: 'block',
        margin: '16px auto',
        padding: '10px 20px',
        backgroundColor: '#e50914',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#b00610',
        },
    },
    planDetails: {
        fontSize: '14px',
        marginTop: '16px',
    },
    containerCard: {
        position: 'relative',
        "@media (max-width: 1400px)": {
            width: '31%',
        },
        "@media (max-width: 940px)": {
            width: '46%',
        },
        "@media (max-width: 540px)": {
            width: '100%',
        },
    },
    blueBarTop: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    }
}))

export default function CardComponent({ userInfo, plan }) {
    const classes = useStyles()

    const validPlan = () => {
        if (userInfo?.info_user?.plan_actual === plan?.name) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <Card key={plan?.id} className={classes.containerCard} sx={{ margin: '10px', border: validPlan() ? '1px solid #78b0ff' : '1px solid #ccc', borderRadius: '8px', }}>

            {/* Linea superior gradient */}
            <Box className={classes.blueBarTop} style={{ height: validPlan() ? '' : '7px', background: 'linear-gradient(45deg, #1E3A8A, #3B82F6, #60A5FA)' }}>
                <Box ml={1} fontSize={12} color={'white'} p={0.5} fontWeight={'bold'}>
                    {validPlan() ? 'Plan actual' : ''}
                </Box>
            </Box>

            {/* Titulo */}
            <Box mt={1} backgroundColor='#f5f5f582' p={3} >
                <Box fontSize={15} fontWeight='bold' color='#5b6065'>
                  Plan
                </Box>
                <Box fontSize={19} fontWeight='bold'  >
                    {plan?.name}
                </Box>

            </Box>

            {/* Contenido inicial */}
            <CardContent>
                <Box >
                    {['Buena calidad de audio y video', 'Ve Netflix sin anuncios', 'Descargas en 1 dispositivo'].map((elem) => {
                        return (
                            <Box key={elem} display={'flex'} >
                                <Box>
                                    <CheckIcon sx={{ color: '#808080', fontSize: '18px' }} />
                                </Box>
                                <Box ml={1} >
                                    <ListItem sx={{ fontSize: '13px', padding: 0, marginBottom: '8px', color: '#808080', fontWeight: 'bold' }}>{elem}</ListItem>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Divider />
                <Box style={{ marginTop: '16px' }}>
                    <Typography variant="h5">{`$ ${plan?.costo_mensual}/mes`}</Typography>
                    {/* <Typography color='#808080' fontSize='20px' variant="body2">(sin impuestos incluidos)</Typography> */}
                </Box>
            </CardContent>

            {/* Botones */}
            <Box pr={2} pl={2}  >
                <Button
                    disabled={validPlan()}
                    fullWidth
                    variant="contained"
                    sx={{ fontWeight: 'bold', textTransform: 'none', backgroundColor: '#e50914', '&:hover': { backgroundColor: '#b00610' } }}
                >
                    Elegir plan
                </Button>
            </Box>

            {/* Linea inferior division */}
            <Box pr={2} pl={2} pt={2} >
                <Divider />
            </Box>

            {/* Que incluye */}
            <CardContent>
                <Typography color='#656363' fontSize={14} variant="subtitle1" gutterBottom><strong>QUÉ INCLUYE</strong></Typography>
                <Typography color='#656363' variant="body2"><strong>Calidad de audio y video:</strong> Buena</Typography>
                <Typography color='#656363' variant="body2"><strong>Resolución:</strong> 720p (HD)</Typography>
                <Typography color='#656363' variant="body2"><strong>Dispositivos compatibles:</strong> TV, computadora, teléfono, tablet</Typography>
                <Typography color='#656363' variant="body2"><strong>Dispositivos del hogar en los que se</strong> 10</Typography>
                <Typography color='#656363' variant="body2"><strong>Dispositivos de descarga:</strong> 22</Typography>
            </CardContent>
        </Card >
    )
}

