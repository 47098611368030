import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
//Helpers
import { formattingSeries } from '../Instant/helpers';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        '@media (max-width: 700px)': {
            margin: theme.spacing(0),
        },
        display: 'flex',
        justifyContent: 'center'
    },
}));

export default function DualChartAdvanced({
    chartData,
    tz
}) {
    const classes = useStyles();

    useEffect(() => {
        const chartsContainer = document.getElementById('instantaneos-avanzado');
        const charts = Object.groupBy(chartData, ({ groupIndex }) => groupIndex);
        const chartKeys = Object.keys(charts);
        const chartCount = chartKeys.length;
        const baseHeight = 700;
        const heightPerChart = chartCount > 1 ? `${400}px` : `${baseHeight}px`;

        for (const chartKey in charts) {
            const chartDataSet = charts[chartKey];
            // Crear un contenedor único para cada gráfico
            const chartDivId = `chart-${chartKey}`;
            const chartDiv = document.createElement('div');
            chartDiv.id = chartDivId;
            chartDiv.style.width = '100%';
            chartDiv.style.height = heightPerChart;
            chartsContainer.appendChild(chartDiv);

            // Crea un nuevo gráfico
            const chart = am4core.create(chartDivId, am4charts.XYChart);

            // Configuración básica del gráfico
            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.baseInterval = {
                timeUnit: 'minute',
                count: 1,
            };
            dateAxis.tooltipDateFormat = 'dd/MM/yyyy HH:mm';

            chart.fontSize = 11;
            chart.language.locale = am4lang_es_ES;
            chart.dateFormatter.timezone = tz;
            chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:ssZ';

            chart.scrollbarX = new am4charts.XYChartScrollbar();
            chart.scrollbarX.parent = chart.bottomAxesContainer;
            chart.scrollbarX.strokeWidth = 1;
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.strokeWidth = 0;
            chart.strokeWidth = 2;

            chart.legend = new am4charts.Legend();

            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [
                {
                    label: '...',
                    menu: [
                        { type: 'pdf', label: 'PDF' },
                        { type: 'csv', label: 'CSV' },
                        { type: 'xlsx', label: 'XLSX' },
                        { type: 'print', label: 'Imprimir' },
                    ],
                },
            ];

            chartDataSet.forEach((dataset, indexDataset) => {
                updateChart(chart, dataset, indexDataset);
            });

            // Deshabilitar el logo si existe
            if (chart?.logo) {
                chart.logo.disabled = true;
            }
        }

        //Funcion que segun la granularidad q selecciono devuelvo una configuracion para la separacion entre puntos,
        // en este caso es 3 veces mas su valor no unir puntos.
        function gapCalc(granularity) {
            let res = 20
            if (granularity) {
                res = Number(granularity) * 4
            }
            return res
        }

        function updateChart(chart, dataset, indexDataset) {
            console.log({ dataset });

            switch (dataset.variable) {
                case 'TENSION_POR_FASE':
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        const phaseName = i === 0 ? 'R' : i === 1 ? 'S' : 'T';
                        const colorOffset = i === 0 ? '' : i === 1 ? '60' : '90';
                        dataset.color = colorOffset;
                        dataset.alias = `Fase ${phaseName} (${dataset.variable})`;
                        addSeriesAndAxis(
                            chart,
                            dataset,
                            dataset.data?.[`V_${phaseName}`],
                            '#.##V',
                            gapCalc(dataset.granularity),
                            indexDataset,
                            dataset.color
                        );
                    }
                    break;
                case 'CORRIENTE_POR_FASE':
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        const phaseName = i === 0 ? 'R' : i === 1 ? 'S' : 'T';
                        const colorOffset = i === 0 ? '' : i === 1 ? '60' : '90';
                        dataset.color = colorOffset;
                        dataset.alias = `Fase ${phaseName} (${dataset.variable})`;
                        addSeriesAndAxis(
                            chart,
                            dataset,
                            dataset?.data?.[`I_${phaseName}`],
                            `#.##'A`,
                            gapCalc(dataset.granularity),
                            indexDataset,
                            dataset.color
                        );
                    }
                    break;
                case 'POTENCIA_ACTIVA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset?.data?.P,
                        `#.##aW`,
                        gapCalc(dataset.granularity),
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'POTENCIA_ACTIVA_POR_FASE':
                    let nameLabelTooltipsP = ''
                    let colorOffsetP = ''             
                    let color = dataset.color       
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        let phaseName = ''
                        if (i === 0) {
                            phaseName = 'P_R'
                            nameLabelTooltipsP = 'Fase R'
                            colorOffsetP = 60
                        }
                        if (i === 1) {
                            phaseName = 'P_S'
                            nameLabelTooltipsP = 'Fase S'
                            colorOffsetP = 65
                        }
                        if (i === 2) {
                            phaseName = 'P_T'
                            nameLabelTooltipsP = 'Fase T'
                            colorOffsetP = 70
                        }
                        if (i === 3) {
                            phaseName = 'P'
                            nameLabelTooltipsP = 'Total'
                            colorOffsetP = 90
                        }
                        color = applyOffsetToHexColor(dataset.color, colorOffsetP);
                        console.log('color',color);
                        
                        dataset.alias =  `${nameLabelTooltipsP} (${dataset.alias})`;
                        addSeriesAndAxis(
                            chart,
                            dataset,
                            dataset.data?.[`${phaseName}`],
                            `#.##aW`,
                            gapCalc(dataset.granularity),
                            indexDataset,
                            color
                        );
                    }
                    break;
                case 'POTENCIA_REACTIVA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset.data?.Q,
                        `#.##aVAr`,
                        gapCalc(dataset.granularity),
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'POTENCIA_REACTIVA_POR_FASE':
                    let nameLabelTooltipsQ = ''
                    let colorOffsetQ = ''
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        let phaseName = ''
                        if (i === 0) {
                            phaseName = 'Q_R'
                            nameLabelTooltipsQ = 'Fase R'
                            colorOffsetQ = '60'
                        }
                        if (i === 1) {
                            phaseName = 'Q_S'
                            nameLabelTooltipsQ = 'Fase S'
                            colorOffsetQ = '65'
                        }
                        if (i === 2) {
                            phaseName = 'Q_T'
                            nameLabelTooltipsQ = 'Fase T'
                            colorOffsetQ = '70'
                        }
                        if (i === 3) {
                            phaseName = 'Q'
                            nameLabelTooltipsQ = 'Total'
                            colorOffsetQ = '90'
                        }
                        dataset.color = colorOffsetQ;
                        dataset.alias =  `${nameLabelTooltipsQ} (${dataset.alias})`;
                        addSeriesAndAxis(
                            chart,
                            dataset,
                            dataset.data?.[`${phaseName}`],
                            `#.##aVAr`,
                            gapCalc(dataset.granularity),
                            indexDataset,
                            dataset.color
                        );
                    }
                    break;
                case 'POTENCIA_ADQUIRIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset?.data?.KW.map(item => ({ ...item, valor: item.valor * 1000 })),
                        '#.##aW',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'ENERGIA_TOTAL_POR_HORA':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset?.data?.KWH.map(item => ({ ...item, valor: item.valor * 1000 })),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'ENERGIA_ACTIVA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset?.data?.KWH.map(item => ({ ...item, valor: item.valor * 1000 })),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'TEMPERATURA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset?.data?.TEMP,
                        `#.## °C`,
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'PRESION_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset?.data?.PRESSURE,
                        `# hPa`,
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'HUMEDAD_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        dataset?.data?.HUMIDITY,
                        `#.## '%`,
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'ENERGIA_ACTIVA_IMPORTADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        formattingSeries(dataset?.data?.KWH_I, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'ENERGIA_ACTIVA_EXPORTADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        formattingSeries(dataset?.data?.KWH_E, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'ENERGIA_ACTIVA_GENERADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        formattingSeries(dataset?.data?.KWH_G, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'ENERGIA_ACTIVA_CONSUMIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        formattingSeries(dataset?.data?.KWH_C, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        formattingSeries(dataset?.data?.KWH_AC, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'POTENCIA_ACTIVA_GENERADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        formattingSeries(dataset?.data?.P_G, dataset.variable, 'INSTANTANEOS'),
                        '#.##aW',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                case 'POTENCIA_ACTIVA_CONSUMIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset,
                        formattingSeries(dataset?.data?.P_C, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        indexDataset,
                        dataset.color
                    );
                    break;
                default:
                    break;
            }
        }


        // if (showSecondary) {
        //     chart.leftAxesContainer.layout = 'vertical';
        //     chartData2.forEach(serie => {
        //         const { chartColor, serieName } = assignSerieValues(serie);
        //         const phases = serie?.medidores[0]?.count_phases;
        //         updateChart(variable2, valueAxis2, serie, serieName, chartColor, phases, 'secondary');
        //     });
        // }

        function applyOffsetToHexColor(hexColor, offset) {
            // Convertir hexadecimal a RGB
            const r = parseInt(hexColor.slice(1, 3), 16);
            const g = parseInt(hexColor.slice(3, 5), 16);
            const b = parseInt(hexColor.slice(5, 7), 16);
        
            // Aplicar el offset y limitar entre 0 y 255
            const newR = Math.min(Math.max(r + offset, 0), 255);
            const newG = Math.min(Math.max(g + offset, 0), 255);
            const newB = Math.min(Math.max(b + offset, 0), 255);
        
            // Convertir de vuelta a hexadecimal
            const toHex = (value) => value.toString(16).padStart(2, '0');
            return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
        }

        function addSeriesAndAxis(chart, dataset, data, format, gap = 20, indexSerie, color) {
            console.log({ dataset });

            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.baseInterval = {
                timeUnit: 'minute',
                count: 1,
            };
            dateAxis.tooltipDateFormat = 'dd/MM/yyyy HH:mm';



            const numberFormatter = new am4core.NumberFormatter();
            numberFormatter.numberFormat = format;
            /////////////////////////
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.zIndex = 1;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.inside = false;
            // valueAxis.height = showSecondary
            //     ? am4core.percent(60)
            //     : am4core.percent(100);
            valueAxis.renderer.labels.template.verticalCenter = 'bottom';
            valueAxis.renderer.labels.template.padding(1, 6, 1, 6);
            valueAxis.renderer.fontSize = '1em';
            valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
            dateAxis.renderer.grid.template.strokeWidth = 1;
            valueAxis.numberFormatter = numberFormatter;
            valueAxis.renderer.line.strokeOpacity = 1;
            valueAxis.renderer.line.strokeWidth = 3;
            valueAxis.renderer.opposite = indexSerie === 0 ? false : true;
            valueAxis.title.text = dataset.variableName;
            ////////////////////////////
            // Si no tengo data disponible no quiero agregar la serie
            if (!dataset.alias)
                return;
            // const seriesId = chart.series.length + 1;
            const series = chart.series.push(new am4charts.LineSeries());
            // Crear un NumberFormatter para la serie (así me garantizo respetar las unidades)
            series.numberFormatter = numberFormatter;
            series.yAxis = valueAxis;
            series.connect = false;


            // gap es un parámetro con valor por defecto 15 y que para energíaTotal y adquiridaTotal es equivalente a 3h (180)
            series.autoGapCount = gap;
            series.dataFields.valueY = 'valor';
            series.dataFields.dateX = 'timestamp';
            series.data = data;
            series.name = dataset.alias;
            series.stroke = am4core.color(color);
            //color de eje 'Y' y numeros del eje
            valueAxis.renderer.line.stroke = series.stroke
            valueAxis.renderer.labels.template.fill = series.stroke;
            series.tooltipText = `{valueY} - ${dataset.alias}`;
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color(color);
            // series.tooltip.autoTextColor = false;
            const circleBullet = series.bullets.push(new am4charts.CircleBullet());
            circleBullet.circle.fill = am4core.color('#fff');
            series.minBulletDistance = 15;
            // chart.scrollbarX.series.push(series);


        }

        return () => {
            am4core.disposeAllCharts();
        };
        // eslint-disable-next-line
    }, [chartData]);

    return (
        <Box className={classes.root}>
            <div
                id={`instantaneos-avanzado`}
                style={{ height: '700px', width: '95%', marginTop: '25px' }}
            />
        </Box>
    );
}
